var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pa-10"},[_c('v-col',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.getCategoryData.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Search","prepend-icon":"mdi-card-search"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{attrs:{"type":"submit","small":"","color":"primary","dark":""}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.categoryData.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h3',[_vm._v("All Category")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',[_vm._v("Total : "+_vm._s(_vm.categoryData.meta ? _vm.categoryData.meta.total : 0))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"add-category","color":"primary"}},[_vm._v(" Add New Category ")])],1)]},proxy:true},{key:"item.parent_category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parent_category ? item.parent_category.name : "")+" ")]}},{key:"item.banner_file",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","width":"200","height":"100","src":item.banner_file.url}})]}},{key:"item.featured",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.categoryStatusChange(item)}},model:{value:(item.featured),callback:function ($$v) {_vm.$set(item, "featured", $$v)},expression:"item.featured"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push('add-category/' + item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("  Edit ")],1)],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteCategory(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  Delete ")],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }