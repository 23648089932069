<template>
  <div>
    <v-tabs fixed-tabs background-color="default">
      <v-tab :to="'/store-order/' + id"> Orders </v-tab>
      <v-tab :to="'/store-payment/' + id"> Payments </v-tab>
      <v-tab :to="'/store-catalogue/' + id"> Catalogue </v-tab>
      <v-tab :to="'/store-detail/' + id"> Store Details </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "StoreHeadingComponent",

  props: ["id"],
};
</script>
