<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-3">
                <v-card class="ma-3 pa-3">
                    <v-card-text>
                        <v-col class="mr-10">
                            <p class="text-h6 text--success mb-5">
                                ₹ {{ order_count.completedOrder }}
                            </p>
                            <p class="text--success mb-5">
                                <v-icon color="success">mdi-store-check</v-icon>&nbsp;Completed
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-3">
                <v-card class="ma-3 pa-3">
                    <v-card-text>
                        <v-col class="mr-10">
                            <p class="text-h6 text--success mb-5">
                                ₹ {{ order_count.dispatchedOrder }}
                            </p>
                            <p class="text--primary mb-5">
                                <v-icon color="primary">mdi-truck</v-icon>&nbsp;Dispatched
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-3">
                <v-card class="ma-3 pa-3">
                    <v-card-text>
                        <v-col class="mr-10">
                            <p class="text-h6 text--success mb-5">
                                ₹ {{ order_count.pendingOrder }}
                            </p>
                            <p class="text--success mb-5">
                                <v-icon color="warning">mdi-account-clock</v-icon>&nbsp;Pending
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-3">
                <v-card class="ma-3 pa-3">
                    <v-card-text>
                        <v-col class="mr-10">
                            <p class="text-h6 text--success mb-5">
                                ₹ {{ order_count.cancelledOrder }}
                            </p>
                            <p class="text--success mb-5">
                                <v-icon color="error">mdi-close-circle-outline</v-icon>&nbsp;Cancelled
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-col class="col-md-12">
            <v-card>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="getOrderData" ref="form">
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    v-model="form.search"
                                    label="Search"
                                    prepend-icon="mdi-card-search"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                    v-model="form.delivery_status"
                                    :items="orderOption"
                                    label="Delivery Status" clearable
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-select
                                    v-model="form.payment_status"
                                    :items="paymentOption"
                                    label="Payment Status" clearable
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="mt-5">
                                <v-btn type="submit" small color="primary" dark> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="orderData.data"
                :loading="loading"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-1"
            >

                <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                        <v-toolbar-title><h4>Orders</h4></v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        Total : {{ total }}
                        <v-spacer></v-spacer>

                    </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link :to="'/orders-view/' + item.id">
                                <v-list-item-title>
                                    <v-icon small> mdi-eye</v-icon>&nbsp; View
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link>
                                <v-list-item-title v-on:click="deleteOrder(item)">
                                    <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:no-data>
                    <v-btn
                        color="primary"
                    >
                        Reset
                    </v-btn>
                </template>

            </v-data-table>
        </v-col>

    </div>

</template>

<script>
import Order from "@/apis/Order";

export default {
    name: 'Order',

    data: () => ({
        loading: false,
        valid: false,
        paymentOption: ["Paid", "Pending"],
        orderOption: ["Pending", "Confirmed", "Processed", "Shipped", "Delivered", "Cancel"],
        headers: [
            {
                text: 'Customer Name',
                align: 'start',
                sortable: false,
                value: 'customer.name',
            },
            {text: 'Email address', value: 'customer.email'},
            {text: 'Store Name', value: 'store.store_name'},
            {text: 'Phone', value: 'customer.mobile'},
            {text: 'Payment Status', value: 'payment_status'},
            {text: 'Delivery Status', value: 'delivery_status'},
            {text: 'Order Code', value: 'order_code'},
            {text: 'Price', value: 'price'},
            {text: 'Options', value: 'actions', sortable: false},
        ],

        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
        },

        options: {},
        total: 0,
        orderData: [],
        order_count: {},
    }),

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 1;
    },

    created() {
        this.getOrderData();
        this.getOrderCount();
    },

    methods: {
        getOrderData() {
            Order.index(this.form)
                .then((response) => {
                    this.orderData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getOrderCount() {
            Order.order_count()
                .then((response) => {
                    this.order_count = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        deleteOrder(order) {
            var conf = confirm("Are you sure ?");

            if (conf) {
                Order.delete(order.id)
                    .then(() => {
                        this.getOrderData();

                        this.$notify({
                            title: "Success",
                            text: "Order Deleted Successfully !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
            }
        }
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getOrderData();
            },
            deep: true,
        },
    },
}

</script>
