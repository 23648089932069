<template>
    <div class="about">
        <v-col class="col-md-12 pa-5">
            <v-form v-model="valid" @submit.prevent="submitProductForm" ref="form">
                <v-row>
                    <v-col col="12" md="8">
                        <!--          first -->
                        <v-card class="mb-4" :loading="loading">
                            <v-card-title class="ml-5">Product Information</v-card-title>
                            <v-divider></v-divider>

                            <v-container fluid>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-select
                                            class="ml-3"
                                            :items="storeOption"
                                            v-model="form.store_id"
                                            item-text="store_name"
                                            item-value="id"
                                            label="Store"
                                            outlined
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-text-field
                                            class="ml-3"
                                            label="Product Name"
                                            outlined
                                            v-model="form.product_name"
                                        ></v-text-field>
                                        <v-text-field
                                            class="ml-3"
                                            label="Minimum Purchase Qty"
                                            outlined
                                            v-model="form.minimum_purchase"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            class="ml-3"
                                            label="Maximum Purchase Qty"
                                            v-model="form.maximum_purchase"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!--          second card-->
                        <v-card class="mb-4">
                            <v-card-title class="ml-5">Product Images</v-card-title>
                            <v-divider></v-divider>

                            <v-container fluid>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-file-input
                                            show-size
                                            counter
                                            outlined
                                            label="Thumbnail Image (300x300)"
                                            v-model="files.thumbnail"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-file-input
                                            outlined
                                            show-size
                                            counter
                                            multiple
                                            label="Gallery Images(600x600)"
                                            v-model="files.images"
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!--          third card-->
                        <v-card class="mb-4">
                            <v-col cols="12">
                                <v-row class="ml-2">
                                    <v-col cols="8">
                                        <v-card-title>Product price, stock</v-card-title>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-sheet>
                                            <v-switch
                                                v-model="form.variant"
                                                inset
                                                false-value="N"
                                                true-value="Y"
                                                label="Variant Product"
                                            ></v-switch>
                                        </v-sheet>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-divider></v-divider>

                            <v-container fluid>
                                <div v-show="form.variant == 'N'">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field
                                                class="ml-3"
                                                label="Unit (e.g. 500 Gram, 2 Litre, 5 Pc etc)"
                                                outlined
                                                v-model="form.product_unit"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.mrp"
                                                label="MRP"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.cost_price"
                                                label="Cost Price"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="form.price"
                                                label="Selling price "
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                label="SKU"
                                                v-model="form.sku"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                :items="stockOption"
                                                item-text="option"
                                                item-value="option"
                                                label="Stock Status"
                                                v-model="form.status"
                                                outlined
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div v-show="form.variant == 'Y'">
                                    <v-row class="ma-2">
                                        <v-col cols="12">
                                            <v-alert color="info" text
                                            >Select an option for this product and then select
                                                choices of each option.
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-2">
                                        <v-col cols="4">
                                            <v-btn class="ma-2" x-small @click="attributeDialog = true">Add Attribute
                                            </v-btn>
                                            <v-select
                                                :items="attributeOption"
                                                item-text="name"
                                                item-value="id"
                                                label="Select an Attribute"
                                                v-model="select.attribute"
                                                return-object
                                                outlined
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-btn class="ma-2" x-small @click="attributeValueDialog = true">Add
                                                Attribute Value
                                            </v-btn>
                                            <v-select
                                                :items="attributeValueOption"
                                                item-text="name"
                                                item-value="id"
                                                label="Select an Attribute Value"
                                                v-model="select.attribute_value"
                                                return-object
                                                outlined
                                                multiple
                                            ></v-select>
                                        </v-col>
                                        <!-- <v-col cols="1" class="mt-2">
                                          <v-fab-transition>
                                            <v-btn v-show="!hidden" color="grey" dark>
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                          </v-fab-transition>
                                        </v-col> -->
                                    </v-row>
                                    <v-simple-table v-if="select.attribute_value">
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left">Variant</th>
                                                <th class="text-left">MRP</th>
                                                <th class="text-left">Cost Price</th>
                                                <th class="text-left">Selling Price</th>
                                                <th class="text-left">Stock</th>
                                                <th class="text-left">SKU</th>
                                                <th class="text-left">Image</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(attribute_value, key) in select.attribute_value"
                                                :key="key">
                                                <td>
                                                    <v-card-text>
                                                        {{ select.attribute.name }}: {{ attribute_value.name }}
                                                    </v-card-text>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="form.variantForm.mrp[key]"
                                                        label="MRP"
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="form.variantForm.cost_price[key]"
                                                        label="Cost Price"
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="form.variantForm.price[key]"
                                                        label="Selling Price"
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-select
                                                        :items="stockOption"
                                                        v-model="form.variantForm.status[key]"
                                                        item-text="option"
                                                        item-value="option"
                                                        label="Stock Status"
                                                        outlined
                                                    ></v-select>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        v-model="form.variantForm.sku[key]"
                                                        label="SKU"
                                                        outlined
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-file-input
                                                        v-model="variantFile.image[key]"
                                                        label="Image"
                                                        outlined
                                                    ></v-file-input>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </div>
                            </v-container>
                        </v-card>
                        <!--          fourth Card-->
                        <!-- <v-card class="mb-4">
                        <v-col cols="12">
                          <v-card-title class="ml-5">Product Discount</v-card-title>
                        </v-col>
                        <v-divider></v-divider>

                        <v-container fluid>
                          <v-row class="ma-2">
                            <v-col cols="3">
                              <div class="">
                                <v-card-text>Discount Date Range</v-card-text>
                              </div>
                            </v-col>
                            <v-col cols="9">
                              <v-text-field label="Select date" outlined></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-card-text>Discount *</v-card-text>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field label="0" outlined></v-text-field>
                            </v-col>
                            <v-col cols="3">
                              <v-select :items="items" label="Flat" outlined></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card> -->
                        <!--          card 5-->
                        <v-card class="mb-4">
                            <v-card-title class="ml-5">Shipping Information</v-card-title>

                            <v-divider></v-divider>

                            <v-container fluid>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Standard delivery time"
                                            outlined
                                            v-model="form.standard_delivery"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Express delivery time"
                                            outlined
                                            v-model="form.express_delivery"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="form.weight"
                                            label="Weight"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Height"
                                            v-model="form.height"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-2">
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Length"
                                            v-model="form.length"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Width"
                                            v-model="form.width"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!--          card 6-->
                        <v-card class="mb-4">
                            <v-card-title class="ml-5">Product Description</v-card-title>

                            <v-divider></v-divider>

                            <v-container fluid>
                                <v-row class="ma-2">
                                    <v-col cols="3">
                                        <div class="">
                                            <v-card-text>Description</v-card-text>
                                        </div>
                                    </v-col>
                                    <v-col cols="9">
                                        <!-- <v-textarea outlined label="Product Description"></v-textarea> -->
                                        <ckeditor
                                            :editor="editor"
                                            v-model="form.description"
                                            :config="editorConfig"
                                        ></ckeditor>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>

                    <v-col col="12" md="4">
                        <v-card>
                            <v-card-title>Product Status</v-card-title>
                            <v-divider></v-divider>
                            <v-row align="center" class="ma-5">
                                <v-select
                                    :items="productOption"
                                    v-model="form.product_status"
                                    item-text="option"
                                    item-value="option"
                                    label="Product Status"
                                    outlined
                                ></v-select>
                            </v-row>
                        </v-card>
                        <v-card>
                            <v-card-title>
                                Product Brand
                                <v-spacer></v-spacer>
                                <v-btn x-small @click="brandDialog = true">Add Brand</v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-row align="center" class="ma-5">
                                <v-select
                                    :items="brandOption"
                                    v-model="form.brand_id"
                                    item-text="name"
                                    item-value="id"
                                    label="Brand"
                                    outlined
                                ></v-select>
                            </v-row>
                        </v-card>
                        <v-card>
                            <v-card-title>
                                Product Category
                                <v-spacer></v-spacer>
                                <v-btn x-small @click="categoryDialog = true">Add Category</v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-row align="center" class="ma-5">
                                <v-treeview
                                    open-all
                                    :selection-type="'independent'"
                                    v-model="form.product_category"
                                    selectable
                                    :items="categoryOption"
                                ></v-treeview>
                            </v-row>
                        </v-card>
                        <v-card>
                            <v-card-title>Product Tags</v-card-title>
                            <v-divider></v-divider>
                            <v-row align="center" class="ma-5">
                                <v-combobox
                                    v-model="form.product_tags"
                                    hide-selected
                                    hint="Type and hit enter to add a tag"
                                    label="Product Tags"
                                    multiple
                                    small-chips
                                    outlined
                                >
                                </v-combobox>
                            </v-row>
                        </v-card>
                        <v-card>
                            <v-card-title>Tax Information</v-card-title>
                            <v-divider></v-divider>

                            <v-row align="center" class="ma-5">
                                <!-- <v-col cols="12">
                                <h4>VAT</h4>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field label="0" outlined></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                  class="ml-3"
                                  :items="items"
                                  label="Flat"
                                  outlined
                                ></v-select>
                              </v-col> -->
                                <v-col cols="12">
                                    <h4>GST</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Tax"
                                        v-model="form.taxForm.gst"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        :items="taxOption"
                                        v-model="form.taxForm.gst_type"
                                        item-text="option"
                                        item-value="option"
                                        label="Tax Type"
                                        outlined
                                    ></v-select>
                                </v-col>
                                <!-- <v-col cols="12">
                                <h4>TAX</h4>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field label="0" outlined></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select
                                  class="ml-3"
                                  :items="items"
                                  label="Flat"
                                  outlined
                                ></v-select>
                              </v-col> -->
                            </v-row>
                        </v-card>

                        <!--          card 8-->
                        <v-card class="mb-4">
                            <v-card-title class="ml-5">SEO Meta Tags</v-card-title>

                            <v-divider></v-divider>

                            <v-container fluid>
                                <v-col class="ma-2">
                                    <v-text-field
                                        label="Meta title"
                                        v-model="form.meta_title"
                                        outlined
                                    ></v-text-field>
                                    <v-textarea
                                        outlined
                                        v-model="form.meta_description"
                                        label="Meta Description"
                                    ></v-textarea>
                                    <v-file-input
                                        outlined
                                        v-model="files.meta_image"
                                        label="Meta Image"
                                    ></v-file-input>
                                </v-col>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
                <div class="text-right">
                    <v-btn type="submit" dark color="orange" :loading="loading" large
                    >Upload Product
                    </v-btn
                    >
                </div>
            </v-form>
        </v-col>

        <!--add brand popup start-->
        <v-dialog v-model="brandDialog" max-width="50%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Brand</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitBrandForm"
                            ref="brandForm"
                        >
                            <v-col cols="11" class="mb-0 ml-5">
                                <v-select
                                    class="ml-3"
                                    :items="storeOption"
                                    v-model="brandForm.store_id"
                                    item-text="store_name"
                                    item-value="id"
                                    label="Store"
                                    outlined
                                ></v-select>

                                <v-text-field
                                    class="ml-3"
                                    label="Enter Name"
                                    outlined
                                    v-model="brandForm.id"
                                    v-show="false"
                                ></v-text-field>

                                <v-text-field
                                    class="ml-3"
                                    label="Enter Name"
                                    outlined
                                    v-model="brandForm.name"
                                ></v-text-field>

                                <v-file-input
                                    outlined
                                    v-model="brandFiles.logo"
                                    accept="image/png, image/jpeg, image/bmp, image/webp"
                                    placeholder="Choose Brand Logo"
                                    prepend-icon="mdi-camera"
                                    label="Brand Logo"
                                ></v-file-input>

                                <v-btn
                                    color="warning"
                                    type="submit"
                                    :loading="loading"
                                    :disabled="loading"
                                    dark
                                >
                                    Add
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--add brand popup end-->

        <!--add category popup start-->
        <v-dialog v-model="categoryDialog" max-width="50%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Category</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitCategoryForm"
                            ref="categoryForm"
                        >
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Category Name"
                                        :rules="categoryRules.name"
                                        v-model="categoryForm.name"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        clearable
                                        class="ml-3"
                                        :items="parentCategoryData"
                                        item-text="name"
                                        item-value="id"
                                        label="Parent Category"
                                        v-model="categoryForm.category_id"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Order Level"
                                        :rules="categoryRules.priority"
                                        v-model="categoryForm.priority"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Banner"
                                        :rules="categoryRules.banner"
                                        v-model="categoryFiles.banner"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Title"
                                        :rules="categoryRules.meta_title"
                                        v-model="categoryForm.meta_title"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Description"
                                        :rules="categoryRules.meta_description"
                                        v-model="categoryForm.meta_description"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Slug"
                                        :rules="categoryRules.slug"
                                        v-model="categoryForm.slug"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Meta Image"
                                        :rules="categoryRules.meta_image"
                                        v-model="categoryFiles.meta_image"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn depressed @click="categoryDialog = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            depressed
                                            color="primary"
                                            :loading="loading"
                                            :disabled="loading"
                                            type="submit"
                                        >
                                            Submit
                                        </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--add category popup end-->

        <!--add attribute popup start-->
        <v-dialog v-model="attributeDialog" max-width="50%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Attribute</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitAttributeForm"
                            ref="attributeForm"
                        >
                            <v-container>
                                <v-row>
                                    <v-col cols="11" class="mb-0 ml-5">
                                        <v-alert class="ml-3" color="info" text
                                        >Attributes are non deletable. You can only add or edit.
                                        </v-alert>

                                        <v-select
                                            class="ml-3"
                                            :items="storeOption"
                                            v-model="attributeForm.store_id"
                                            item-text="store_name"
                                            item-value="id"
                                            label="Store"
                                            outlined
                                        ></v-select>

                                        <v-text-field
                                            class="ml-3"
                                            label="Enter Id"
                                            outlined
                                            v-model="attributeForm.id"
                                            v-show="false"
                                        ></v-text-field>

                                        <v-text-field
                                            class="ml-3"
                                            label="Enter Name"
                                            outlined
                                            v-model="attributeForm.name"
                                            :rules="attributeRules.name"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="11" class="text-right mt-0">
                                        <v-btn
                                            color="warning"
                                            type="submit"
                                            :loading="loading"
                                            :disabled="loading"
                                            dark
                                        >
                                            Add
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--add attribute popup end-->

        <!--add attribute value popup start-->
        <v-dialog v-model="attributeValueDialog" max-width="50%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Attribute Value</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitAttributeValueForm"
                            ref="attributeValueForm"
                        >
                            <v-text-field
                                class="ml-3"
                                label="Enter Name"
                                outlined
                                v-model="attributeValueForm.name"
                                :rules="attributeValueRules.name"
                            ></v-text-field>

                            <v-col cols="11" class="text-right mt-0">
                                <v-btn
                                    color="warning"
                                    type="submit"
                                    :loading="loading"
                                    :disabled="loading"
                                    dark
                                >
                                    Add
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--add attribute value popup end-->
    </div>
</template>

<script>
import Attribute from "@/apis/Attributes";
import Brands from "@/apis/Brands";
import Category from "@/apis/Category";
import Store from "@/apis/Store";
import Product from "@/apis/Product";
import ProductVariant from "@/apis/ProductVariant";
import ProductTax from "@/apis/ProductTax";
import ProductTag from "@/apis/ProductTag";
import ProductImage from "@/apis/ProductImage";
import ProductCategory from "@/apis/ProductCategory";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Attributes from "@/apis/Attributes";
import AttributeValue from "@/apis/AttributeValue";

export default {
    name: "AddProductView",

    async created() {
        this.$parent.$parent.$parent.selectedItem = 3;

        await this.getStoreData();

        if (this.$route.params.id) {
            this.form.id = this.$route.params.id;

            await this.getProductDetail();
        }
    },

    mounted() {
    },

    data: () => ({
        valid: false,
        loading: false,

        form: {
            id: "",
            store_id: "",
            product_name: "",
            product_unit: "",
            minimum_purchase: "",
            maximum_purchase: "",
            variant: "N",
            price: "",
            mrp: "",
            cost_price: "",
            status: "",
            sku: "",
            product_status: "",
            brand_id: "",
            standard_delivery: "",
            express_delivery: "",
            weight: "",
            height: "",
            length: "",
            width: "",
            description: "",
            meta_title: "",
            meta_description: "",
            product_category: [],
            product_tags: [],

            variantForm: {
                id: [],
                attribute_id: "",
                attribute_value_id: [],
                price: [],
                mrp: [],
                cost_price: [],
                status: [],
                sku: [],
            },

            taxForm: {
                id: "",
                vat: "",
                vat_type: "",
                gst: "",
                gst_type: "",
                tax: "",
                tax_type: "",
            },
        },

        variantFile: {
            image: [],
        },
        files: {
            thumbnail: null,
            images: [],
            meta_image: null,
        },

        //TODO : Create Rules for Form Submission

        select: {
            attribute: {},
            attribute_value: [],
        },

        stockOption: [{option: "In Stock"}, {option: "Out of Stock"}],
        productOption: [{option: "Published"}, {option: "Draft"}],
        taxOption: [{option: "Flat"}, {option: "Percent"}],
        attributeOption: [],
        attributeValueOption: [],
        brandOption: [],
        categoryOption: [],
        storeOption: [],

        editor: ClassicEditor,
        editorConfig: {
            toolbarGroups: [
                {name: "basicstyles", groups: ["basicstyles", "cleanup"]},
                {
                    name: "paragraph",
                    groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"],
                },
                {name: "links", groups: ["links"]},
            ],
        },

        productData: {},

        searchForm: {
            store_id: null,
        },

        brandDialog: false,
        brandForm: {
            id: "",
            name: "",
            store_id: "",
        },
        brandFiles: {
            logo: null,
        },

        categoryDialog: false,
        categoryRules: {
            name: [(v) => !!v || "Category Name is required"],
            priority: [(v) => !!v || "Priority is required"],
            meta_title: [(v) => !!v || "Meta Title is required"],
            meta_description: [(v) => !!v || "Meta Description is required"],
            slug: [(v) => !!v || "Slug is required"],
            banner: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Banner size should be less than 2 MB!",
            ],
            meta_image: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Meta Image size should be less than 2 MB!",
            ],
        },
        parentCategoryData: [],
        parentCategoryForm: {
            store_id: "",
        },
        categoryForm: {
            name: "",
            category_id: "",
            priority: "",
            meta_title: "",
            meta_description: "",
            slug: "",
            id: "",
            store_id: "",
        },
        categoryFiles: {
            meta_image: null,
            banner: null,
        },

        attributeDialog: false,
        attributeForm: {
            id: "",
            name: "",
            store_id: "",
        },
        attributeRules: {
            name: [(v) => !!v || "Attribute Name is required"],
        },

        attributeValueDialog: false,
        attributeValueForm: {
            id: "",
            attribute_id: "",
            name: "",
        },
        attributeValueRules: {
            name: [(v) => !!v || "Attribute Name is required"],
        },
    }),

    methods: {
        getAttributeData: async function () {
            return Attribute.index(this.searchForm)
                .then((response) => {
                    this.attributeOption = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getBrandData: async function () {
            return Brands.index(this.searchForm)
                .then((response) => {
                    this.brandOption = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getStoreData: async function () {
            return Store.index()
                .then((response) => {
                    this.storeOption = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getCategoryData: async function () {
            return Category.get_tree(this.searchForm)
                .then((response) => {
                    this.categoryOption = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getProductDetail: async function () {
            Product.show(this.form.id)
                .then((response) => {
                    this.productData = response.data.data;

                    this.form.store_id = this.productData.store_id;
                    this.form.product_name = this.productData.product_name;
                    this.form.product_unit = this.productData.product_unit;
                    this.form.minimum_purchase = this.productData.minimum_purchase;
                    this.form.maximum_purchase = this.productData.maximum_purchase;
                    this.form.variant = this.productData.variant;
                    this.form.mrp = this.productData.mrp;
                    this.form.cost_price = this.productData.cost_price;
                    this.form.price = this.productData.price;
                    this.form.status = this.productData.status;
                    this.form.sku = this.productData.sku;
                    this.form.product_status = this.productData.product_status;
                    this.form.brand_id = this.productData.brand_id;
                    this.form.standard_delivery = (this.productData.standard_delivery != 'null') ? this.productData.standard_delivery : "";
                    this.form.express_delivery = (this.productData.express_delivery != 'null') ? this.productData.express_delivery : "";
                    this.form.weight = (this.productData.weight != 'null') ? this.productData.weight : "";
                    this.form.height = (this.productData.height != 'null') ? this.productData.height : "";
                    this.form.length = (this.productData.length != 'null') ? this.productData.length : "";
                    this.form.width = (this.productData.width != 'null') ? this.productData.width : "";
                    this.form.description = this.productData.description;
                    this.form.meta_title = this.productData.meta_title;
                    this.form.meta_description = this.productData.meta_description;

                    for (var product_category in this.productData.product_category) {
                        this.form.product_category[product_category] =
                            this.productData.product_category[product_category].category_id;
                    }

                    for (var product_tags in this.productData.product_tags) {
                        this.form.product_tags[product_tags] =
                            this.productData.product_tags[product_tags].tags;
                    }

                    if (this.productData.product_tax) {
                        this.form.taxForm.id = this.productData.product_tax.id;
                        this.form.taxForm.vat = this.productData.product_tax.vat;
                        this.form.taxForm.vat_type = this.productData.product_tax.vat_type;
                        this.form.taxForm.gst = this.productData.product_tax.gst;
                        this.form.taxForm.gst_type = this.productData.product_tax.gst_type;
                        this.form.taxForm.tax = this.productData.product_tax.tax;
                        this.form.taxForm.tax_type = this.productData.product_tax.tax_type;
                    }

                    if (this.productData.variant == "Y") {
                        for (var product_variant in this.productData.product_variant) {
                            this.select.attribute =
                                this.productData.product_variant[product_variant].attribute;

                            this.attributeValueOption = this.select.attribute.attribute_value;

                            this.select.attribute_value[product_variant] =
                                this.productData.product_variant[
                                    product_variant
                                    ].attribute_value;

                            this.form.variantForm.attribute_id =
                                this.productData.product_variant[product_variant].attribute_id;

                            this.form.variantForm.id[product_variant] =
                                this.productData.product_variant[product_variant].id;

                            this.form.variantForm.attribute_value_id[product_variant] =
                                this.productData.product_variant[
                                    product_variant
                                    ].attribute_value_id;

                            this.form.variantForm.price[product_variant] =
                                this.productData.product_variant[product_variant].price;
                            this.form.variantForm.mrp[product_variant] =
                                this.productData.product_variant[product_variant].mrp;
                            this.form.variantForm.cost_price[product_variant] =
                                this.productData.product_variant[product_variant].cost_price;
                            this.form.variantForm.status[product_variant] =
                                this.productData.product_variant[product_variant].status;
                            this.form.variantForm.sku[product_variant] =
                                this.productData.product_variant[product_variant].sku;
                        }
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        submitProductForm: async function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.form) {
                    fd.append(element, this.form[element]);
                }

                for (var fileElement in this.files) {
                    fd.append(fileElement, this.files[fileElement]);
                }

                if (this.form.id) {
                    fd.append("_method", "PATCH");
                }

                var checkResponse = true;
                await Product.store(fd, this.form.id)
                    .then(async (response) => {
                        this.form.id = response.data.data.id;

                        /************* Product Variant Store ******************/
                        const productVariantFd = new FormData();

                        productVariantFd.append("product_id", this.form.id);
                        productVariantFd.append(
                            "attribute_id",
                            this.form.variantForm.attribute_id
                        );
                        for (var elementId in this.form.variantForm.id) {
                            productVariantFd.append(
                                "id[]",
                                this.form.variantForm.id[elementId]
                            );
                        }
                        for (var element in this.form.variantForm.attribute_value_id) {
                            console.log(element);

                            productVariantFd.append(
                                "attribute_value_id[]",
                                this.form.variantForm.attribute_value_id[element]
                            );
                            productVariantFd.append(
                                "price[]",
                                this.form.variantForm.price[element]
                            );
                            productVariantFd.append(
                                "mrp[]",
                                this.form.variantForm.mrp[element]
                            );
                            productVariantFd.append(
                                "cost_price[]",
                                this.form.variantForm.cost_price[element]
                            );
                            productVariantFd.append(
                                "status[]",
                                this.form.variantForm.status[element]
                            );
                            productVariantFd.append(
                                "sku[]",
                                this.form.variantForm.sku[element]
                            );
                        }
                        for (var elementImg in this.variantFile.image) {
                            productVariantFd.append(
                                "image[]",
                                this.variantFile.image[elementImg]
                            );
                        }

                        if (this.form.variantForm.attribute_id)
                            await ProductVariant.store(productVariantFd)
                                .catch((error) => {
                                    this.loading = false;
                                    checkResponse = false;

                                    var text = error.response.data.message;
                                    if (error.response.data.errors) {
                                        for (var value in error.response.data.errors) {
                                            if (text) text += "<br>";
                                            text += error.response.data.errors[value];
                                        }
                                    }

                                    this.$notify({
                                        title: "Error",
                                        text: text,
                                        type: "error",
                                        duration: 10000,
                                    });
                                    return false;
                                });
                        /************* End Product Variant Store **************/

                        /************* Product Tax Store **********************/
                        const productTaxFd = new FormData();

                        productTaxFd.append("product_id", this.form.id);
                        for (var elementTax in this.form.taxForm) {
                            productTaxFd.append(elementTax, this.form.taxForm[elementTax]);
                        }

                        if (this.form.taxForm.id) {
                            productTaxFd.append("_method", "PATCH");
                        }

                        if (this.form.taxForm.gst)
                            await ProductTax.store(productTaxFd, this.form.taxForm.id)
                                .catch((error) => {
                                    this.loading = false;
                                    checkResponse = false;

                                    var text = error.response.data.message;
                                    if (error.response.data.errors) {
                                        for (var value in error.response.data.errors) {
                                            if (text) text += "<br>";
                                            text += error.response.data.errors[value];
                                        }
                                    }

                                    this.$notify({
                                        title: "Error",
                                        text: text,
                                        type: "error",
                                        duration: 10000,
                                    });
                                    return false;
                                });
                        /************* End Product Tax Store ******************/

                        /************* Product Tag Store **********************/
                        const productTagFd = new FormData();

                        productTagFd.append("product_id", this.form.id);
                        for (var elementTag in this.form.product_tags) {
                            productTagFd.append("tags[]", this.form.product_tags[elementTag]);
                        }

                        await ProductTag.store(productTagFd)
                            .catch((error) => {
                                this.loading = false;
                                checkResponse = false;

                                var text = error.response.data.message;
                                if (error.response.data.errors) {
                                    for (var value in error.response.data.errors) {
                                        if (text) text += "<br>";
                                        text += error.response.data.errors[value];
                                    }
                                }

                                this.$notify({
                                    title: "Error",
                                    text: text,
                                    type: "error",
                                    duration: 10000,
                                });
                                return false;
                            });
                        /************* End Product Tag Store ******************/

                        /************* Product Image Store ********************/
                        if (this.files.images.length != 0) {
                            const productImageFd = new FormData();

                            productImageFd.append("product_id", this.form.id);
                            for (var elementImage in this.files.images) {
                                productImageFd.append(
                                    "image[]",
                                    this.files.images[elementImage]
                                );
                            }

                            await ProductImage.store(productImageFd)
                                .catch((error) => {
                                    this.loading = false;
                                    checkResponse = false;

                                    var text = error.response.data.message;
                                    if (error.response.data.errors) {
                                        for (var value in error.response.data.errors) {
                                            if (text) text += "<br>";
                                            text += error.response.data.errors[value];
                                        }
                                    }

                                    this.$notify({
                                        title: "Error",
                                        text: text,
                                        type: "error",
                                        duration: 10000,
                                    });
                                    return false;
                                });
                        }
                        /************* End Product Image Store ****************/

                        /************* Product Category Store *****************/
                        const productCategoryFd = new FormData();

                        productCategoryFd.append("product_id", this.form.id);
                        for (var elementCategory in this.form.product_category) {
                            productCategoryFd.append(
                                "category_id[]",
                                this.form.product_category[elementCategory]
                            );
                        }
                        await ProductCategory.store(productCategoryFd)
                            .catch((error) => {
                                this.loading = false;
                                checkResponse = false;

                                var text = error.response.data.message;
                                if (error.response.data.errors) {
                                    for (var value in error.response.data.errors) {
                                        if (text) text += "<br>";
                                        text += error.response.data.errors[value];
                                    }
                                }

                                this.$notify({
                                    title: "Error",
                                    text: text,
                                    type: "error",
                                    duration: 10000,
                                });
                                return false;
                            });
                        /************* End Product Category Store *************/

                        if (checkResponse == true) {
                            this.loading = false;

                            this.$notify({
                                title: "Success",
                                text: "Product Information Updated !!",
                                type: "success",
                                duration: 10000,
                            });

                            this.$router.push({name: "products"});
                        }

                    })
                    .catch((error) => {
                        console.log(error);

                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        submitBrandForm() {
            if (this.$refs.brandForm.validate()) {
                const fd = new FormData();

                for (var element in this.brandForm) {
                    fd.append(element, this.brandForm[element]);
                }

                for (var fileElement in this.brandFiles) {
                    fd.append(fileElement, this.brandFiles[fileElement]);
                }

                if (this.brandForm.id != "") {
                    fd.append("_method", "PATCH");
                }

                Brands.store(fd, this.brandForm.id)
                    .then((response) => {
                        this.getBrandData();
                        this.$refs.brandForm.reset();

                        this.brandDialog = false;

                        this.$notify({
                            title: "Success",
                            text: "Brand Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.form.brand_id = response.data.data.id;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        getCategoryParentData: function () {
            Category.get_parent(this.parentCategoryForm)
                .then((response) => {
                    this.parentCategoryData = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        submitCategoryForm: function () {
            if (this.$refs.categoryForm.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.categoryForm) {
                    fd.append(element, this.categoryForm[element]);
                }

                for (var fileElement in this.categoryFiles) {
                    fd.append(fileElement, this.categoryFiles[fileElement]);
                }

                if (this.categoryForm.id) {
                    fd.append("_method", "PATCH");
                }

                Category.store(fd, this.categoryForm.id)
                    .then(() => {
                        this.loading = false;
                        this.$refs.form.reset();

                        this.$notify({
                            title: "Success",
                            text: "Category Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getCategoryParentData();
                        this.getCategoryData();
                        this.categoryDialog = false;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        submitAttributeForm() {
            if (this.$refs.attributeForm.validate()) {
                Attributes.store(this.attributeForm)
                    .then((response) => {
                        this.getAttributeData();
                        this.$refs.attributeForm.reset();
                        this.$notify({
                            title: "Success",
                            text: "Attribute Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });
                        this.attributeDialog = false;

                        this.form.variantForm.attribute_id = response.data.data.id;
                        this.attributeValueForm.attribute_id = response.data.data.id;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });

            }
        },

        submitAttributeValueForm() {
            if (this.$refs.attributeValueForm.validate()) {
                AttributeValue.store(this.attributeValueForm)
                    .then(async () => {
                        await this.getAttributeData();
                        this.attributeValueOption = this.select.attribute.attribute_value;
                        this.$refs.attributeValueForm.reset();
                        this.$notify({
                            title: "Success",
                            text: "Attribute Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.attributeValueDialog = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },

    watch: {
        "form.store_id": async function (val) {
            this.searchForm.store_id = val;
            this.brandForm.store_id = val;
            this.categoryForm.store_id = val;
            this.parentCategoryForm.store_id = val;
            this.attributeForm.store_id = val;
            await this.getAttributeData();
            await this.getBrandData();
            await this.getCategoryData();
            await this.getCategoryParentData();
        },

        "select.attribute": function () {
            this.form.variantForm.attribute_id = this.select.attribute.id;
            this.attributeValueForm.attribute_id = this.select.attribute.id;
            this.attributeValueOption = this.select.attribute.attribute_value;
        },

        "select.attribute_value": function () {
            this.form.variantForm.attribute_value_id = [];
            for (var attribute_value in this.select.attribute_value) {
                this.form.variantForm.attribute_value_id[attribute_value] =
                    this.select.attribute_value[attribute_value].id;
            }
        },
    },
};
</script>
