<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <StoreHeadingComponent :active="0" :id="id"/>

            </v-col>
        </v-row>

        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="orderData.data"
                :loading="loading"
                :options.sync="options"
                :server-items-length="total"
                class="elevation-1"
            >

                <template v-slot:top>
                    <v-toolbar
                        flat
                    >
                        <v-toolbar-title><h4>Orders</h4></v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        Total : {{ total }}
                        <v-spacer></v-spacer>

                    </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link :to="'/orders-view/' + item.id">
                                <v-list-item-title>
                                    <v-icon small> mdi-eye</v-icon>&nbsp; View
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link>
                                <v-list-item-title v-on:click="deleteOrder(item)">
                                    <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:no-data>
                    <v-btn
                        color="primary"
                    >
                        Reset
                    </v-btn>
                </template>

            </v-data-table>
        </v-col>

    </div>
</template>

<script>
import StoreHeadingComponent from "../../components/StoreHeadingComponent";
import Order from "@/apis/Order";

export default {
    name: 'StoreOrderView',

    components: {
        StoreHeadingComponent
    },

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.form.store_id = this.$route.params.id;
        this.id = this.$route.params.id;

        this.getOrderData();
        this.getOrderCount();
    },

    data: () => ({
        loading: false,
        valid: false,
        id: "",
        paymentOption: ["Paid", "Pending"],
        orderOption: ["Pending", "Confirmed", "Processed", "Shipped", "Delivered", "Cancel"],
        headers: [
            {
                text: 'Customer Name',
                align: 'start',
                sortable: false,
                value: 'customer.name',
            },
            {text: 'Email address', value: 'customer.email'},
            {text: 'Store Name', value: 'store.store_name'},
            {text: 'Phone', value: 'customer.mobile'},
            {text: 'Payment Status', value: 'payment_status'},
            {text: 'Delivery Status', value: 'delivery_status'},
            {text: 'Order Code', value: 'order_code'},
            {text: 'Price', value: 'price'},
            {text: 'Options', value: 'actions', sortable: false},
        ],

        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            store_id: "",
        },

        options: {},
        total: 0,
        orderData: [],
        order_count: {},
    }),

    methods: {
        getOrderData() {
            Order.index(this.form)
                .then((response) => {
                    this.orderData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getOrderCount() {
            Order.order_count(this.form)
                .then((response) => {
                    this.order_count = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        deleteOrder(order) {
            var conf = confirm("Are you sure ?");

            if (conf) {
                Order.delete(order.id)
                    .then(() => {
                        this.getOrderData();

                        this.$notify({
                            title: "Success",
                            text: "Order Deleted Successfully !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
            }
        }
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getOrderData();
            },
            deep: true,
        },
    },
}
</script>
