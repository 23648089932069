import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/product-image", {
            params: form
        });
    },

    async store(form) {
        return Api.post("/product-image", form);
    },

    async show(id) {
        return Api.post("/product-image/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/product-image/" + id, form);
    },

    async delete(id) {
        return Api.delete("/product-image/" + id);
    },
};
