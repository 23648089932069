import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/brands", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/brands/" + id, form);
    },

    async show(id) {
        return Api.post("/brands/" + id);
    },

    async update(form, id) {
        return Api.patch("/brands/" + id, form);
    },

    async delete(id) {
        return Api.delete("/brands/" + id);
    },
};
