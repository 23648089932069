import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        logo: require('../assets/images/logo.png'),
        appName: 'Essen Mart',

        baseUrl: (process.env.NODE_ENV === 'production') ? "https://api.essenmart.com" : "http://localhost:8000/",

        adminLogin: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")) : null,
        token: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).access_token : null,
        firstName: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).firstName : null,
        lastName: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).lastName : null,
        email: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).email : null,
        profile: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).profile : null,
        adminId: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).adminId : null,
        created_at: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).created_at : null,

        nextUrl: null,
    },

    getters: {
        loggedIn: state => {
            return state.token !== null
        },
    },
    mutations: {},
    actions: {},
    modules: {}
})
