<template>
  <div class="about pa-10">
    <v-col class="col-md-12">
      <v-row>
        <v-col cols="12" md="7">
          <v-data-table
            :headers="headers"
            :items="attributeData.data"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h4>Attributes</h4> </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                Total :
                {{ attributeData.meta ? attributeData.meta.total : 0 }}
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.attribute_value="{ item }">
              <div v-if="item.attribute_value">
                <v-chip
                  v-for="(value, key) in item.attribute_value"
                  :key="key"
                  class="ma-2"
                  color="green"
                  text-color="white"
                  small
                >
                  {{ value.name }}
                </v-chip>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                    Actions
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="cursor: pointer">
                    <v-list-item-title v-on:click="editAttribute(item)">
                      <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item style="cursor: pointer">
                    <v-list-item-title
                      v-on:click="
                        $router.push('/attributes-listing/' + item.id)
                      "
                    >
                      <v-icon small> mdi-cog</v-icon>&nbsp; Values
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="getAttributesData"> Reset </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="5">
          <v-card>
            <v-card-title><h4>Add new attribute</h4></v-card-title>
            <v-divider></v-divider>
            <v-form
              v-model="valid"
              @submit.prevent="submitAttributeForm"
              ref="addForm"
            >
              <v-container>
                <v-row>
                  <v-col cols="11" class="mb-0 ml-5">
                    <v-alert class="ml-3" color="info" text
                      >Attributes are non deletable. You can only add or edit.
                    </v-alert>

                    <v-select
                      class="ml-3"
                      :items="storeOption"
                      v-model="addForm.store_id"
                      item-text="store_name"
                      item-value="id"
                      label="Store"
                      outlined
                    ></v-select>

                    <v-text-field
                      class="ml-3"
                      label="Enter Id"
                      outlined
                      v-model="addForm.id"
                      v-show="false"
                    ></v-text-field>

                    <v-text-field
                      class="ml-3"
                      label="Enter Name"
                      outlined
                      v-model="addForm.name"
                      :rules="rules.name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="11" class="text-right mt-0">
                    <v-btn
                      color="warning"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                      dark
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import Attributes from "@/apis/Attributes";
import Store from "@/apis/Store";

export default {
  name: "AttributesView",

  data: () => ({
    valid: false,
    loading: false,
    headers: [
      {
        text: "Attribute Name",
        align: "start",
        value: "name",
      },
      { text: "Store", sortable: false, value: "store.store_name" },
      { text: "Values", sortable: false, value: "attribute_value" },
      { text: "Actions", value: "actions" },
    ],
    form: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    options: {},
    total: 0,
    attributeData: [],
    storeOption: [],

    addForm: {
      id: "",
      name: "",
      store_id: "",
    },
    rules: {
      name: [(v) => !!v || "Attribute Name is required"],
    },
  }),

  mounted() {
    this.$parent.$parent.$parent.selectedItem = 3;
    this.getStoreData();
  },

  methods: {
    getStoreData: function () {
      Store.index()
        .then((response) => {
          this.storeOption = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    getAttributesData() {
      Attributes.index(this.form)
        .then((response) => {
          this.attributeData = response.data;
          this.loading = false;
          this.total = response.data.meta.total;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },

    submitAttributeForm() {
      if (this.$refs.addForm.validate()) {
        if (this.addForm.id) {
          Attributes.update(this.addForm, this.addForm.id)
            .then(() => {
              this.getAttributesData();
              this.$refs.addForm.reset();

              this.$notify({
                title: "Success",
                text: "Attribute Information Updated !!",
                type: "success",
                duration: 10000,
              });
            })
            .catch((error) => {
              this.loading = false;

              var text = error.response.data.message;
              if (error.response.data.errors) {
                for (var value in error.response.data.errors) {
                  if (text) text += "<br>";
                  text += error.response.data.errors[value];
                }
              }

              this.$notify({
                title: "Error",
                text: text,
                type: "error",
                duration: 10000,
              });
            });
        } else {
          Attributes.store(this.addForm)
            .then(() => {
              this.getAttributesData();
              this.$refs.addForm.reset();
              this.$notify({
                title: "Success",
                text: "Attribute Information Updated !!",
                type: "success",
                duration: 10000,
              });
            })
            .catch((error) => {
              this.loading = false;

              var text = error.response.data.message;
              if (error.response.data.errors) {
                for (var value in error.response.data.errors) {
                  if (text) text += "<br>";
                  text += error.response.data.errors[value];
                }
              }

              this.$notify({
                title: "Error",
                text: text,
                type: "error",
                duration: 10000,
              });
            });
        }
      }
    },

    editAttribute(attribute) {
      this.addForm.store_id = attribute.store_id;
      this.addForm.name = attribute.name;
      this.addForm.id = attribute.id;
    },
  },

  watch: {
    options: {
      handler(val) {
        this.form.page = val.page;
        this.form.itemsPerPage = val.itemsPerPage;
        this.form.sortBy = val.sortBy;
        this.form.sortDesc = val.sortDesc;
        this.getAttributesData();
      },
      deep: true,
    },
  },
};
</script>