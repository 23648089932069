<template>
    <div class="about pa-10">
        <v-col class="col-md-12">
            <v-card class="pa-5" v-if="orderData">
                <v-card-title>Order Details</v-card-title>
                <v-divider></v-divider>
                <v-row class="pa-7">
                    <v-col cols="6">
                        <h4 class="mb-3">Customer info</h4>


                        <tbody>
                        <tr>
                            <td>Name:</td>
                            <td>{{ orderData.customer.name }}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{{ orderData.customer.email }}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{{ orderData.customer.mobile }}</td>
                        </tr>
                        </tbody>

                    </v-col>
                    <v-col cols="3">
                        <v-select
                            @change="updateOrder"
                            :items="paymentOption"
                            v-model="form.payment_status"
                            item-text="title"
                            item-value="id"
                            label="Payment Status"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select
                            @change="updateOrder"
                            :items="orderOption"
                            v-model="form.delivery_status"
                            item-text="option"
                            item-value="option"
                            label="Delivery Status"
                            outlined
                            dense
                        ></v-select>

                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row class="pa-7">

                    <v-col cols="4" class="text-left">
                        <v-row>
                            <v-col cols="6">
                                <h4 class="mb-3">Shipping Address</h4>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address1 }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address2 }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address_name }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.contact_name }}</p>

                            </v-col>

                            <v-col cols="6">
                                <h4 class="mb-3">Billing Address</h4>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address1 }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address2 }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.address_name }}</p>
                                <p class="mb-1">{{ orderData.order_address.customer_address.contact_name }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="5">


                    </v-col>
                    <v-col cols="3">
                        <v-row>
                            <v-col cols="6">
                                <h4 class="mb-3"></h4>
                                <p class="mb-1">Order code </p>
                                <p class="mb-1">Order Date</p>
                                <p class="mb-1">Payment Method</p>
                            </v-col>

                            <v-col cols="6">
                                <h4 class="mb-3"></h4>
                                <p class="mb-1"><b>{{ orderData.order_code }}</b></p>
                                <p class="mb-1"><b>{{ orderData.created_at }}</b></p>
                                <p class="mb-1"><b>Wallet</b></p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-simple-table>

                    <thead>
                    <tr>
                        <th>
                            <h3>Product</h3>
                        </th>
                        <th>
                            <h3> Qty</h3>
                        </th>
                        <th>
                            <h3> Unit price</h3>
                        </th>
                        <th>
                            <h3> Total</h3>
                        </th>
                    </tr>
                    </thead>
                    <tbody v-for="(orderProduct,key)  in orderProduct.data" :key="key">
                    <tr class="pa-5">
                        <td>{{ orderProduct.product.product_name }}</td>
                        <td>{{ orderProduct.quantity }}</td>
                        <td>{{ orderProduct.product.price }}</td>
                        <td>{{ orderProduct.quantity * orderProduct.product.price }}</td>
                    </tr>
                    </tbody>

                </v-simple-table>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="8"></v-col>
                    <v-col cols="4">
                        <v-simple-table class="pa-7">
                            <tbody>
                            <tr>
                                <th>Sub Total :</th>
                                <td>
                                    <h3> {{ orderData.price }}</h3>
                                </td>
                            </tr>
                            <tr>
                                <th>Total :</th>
                                <td><h3> {{ orderData.price }}</h3></td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </div>
</template>

<script>

import Order from "@/apis/Order";
import OrderProduct from "@/apis/OrderProduct";

export default {
    name: 'OrdersView',

    data: () => ({
        dialog: false,
        dialogDelete: false,
        customer: "",
        orderData: "",
        orderProduct: "",
        paymentOption: ["Paid", "Pending"],
        orderOption: ["Pending", "Confirmed", "Processed", "Shipped", "Delivered", "Cancel"],

        form: {
            payment_status: "",
            delivery_status: "",
        },
        orderId: ''
    }),

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 1;
    },

    computed: {},

    created() {
        this.orderId = this.$route.params.id

        this.getOrderData();
        this.getOrderProductData();
    },

    methods: {
        getOrderData() {
            Order.show(this.orderId)
                .then((response) => {
                    this.orderData = response.data.data;

                    this.form.payment_status = this.orderData.payment_status;
                    this.form.delivery_status = this.orderData.delivery_status;
                })
        },

        getOrderProductData() {
            OrderProduct.index({
                order_id: this.orderId
            })
                .then((response) => {
                    this.orderProduct = response.data;

                })
        },

        updateOrder() {
            const fd = new FormData();

            for (var element in this.form) {
                fd.append(element, this.form[element]);
            }

            Order.update(fd, this.orderId)
                .then((response) => {
                    this.orderData = response.data.data;

                    this.$notify({
                        title: "Success",
                        text: "Order Information Updated !!",
                        type: "success",
                        duration: 10000,
                    });
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        }
    },
}

</script>
