<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <p class="text-h5 text--success ">
                    AnalyticsView
                </p>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    name: 'AnalyticsView',

    mounted() {
        this.$parent.selectedItem = 1;
    }
}
</script>
