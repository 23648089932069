<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <StoreHeadingComponent :active="1" :id="id"/>

            </v-col>
        </v-row>
        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="paymentData.data"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                class="elevation-1"
            >

                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title><h4>Revenue</h4></v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </template>
                <template v-slot:item.amount="{item}">
                    {{ item.currency }} {{ item.amount }}
                </template>
                <template v-slot:no-data>
                    <v-btn
                        color="primary"
                        @click="initialize"
                    >
                        Reset
                    </v-btn>
                </template>

            </v-data-table>
        </v-col>

    </div>
</template>

<script>
import StoreHeadingComponent from "../../components/StoreHeadingComponent";
import Payment from "@/apis/Payment";

export default {
    name: 'StorePaymentView',

    components: {
        StoreHeadingComponent
    },

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.id = this.$route.params.id;
        this.form.store_id = this.$route.params.id;
    },

    data: () => ({
        id: null,
        valid: false,
        loading: true,
        paymentData: [],
        form: {
            page: 1,
            search: "",
            itemsPerPage: 10,
            store_id: null,
            sortBy: [],
            sortDesc: [],
        },
        options: {},
        total: 0,
        headers: [
            {text: 'Amount', value: "amount"},
            {text: 'Order ID', value: 'order.order_code'},
            {text: 'Charge ID', value: 'charge_id'},
            {text: 'Customer IP', value: 'customer_ip'},
            {text: 'Customer Name', value: 'customer.name'},
            {text: 'Email', value: 'email'},
            {text: 'Payment Gateway', value: 'payment_gateway.name'},
            {text: 'Status', value: 'status'},
            {text: 'Created At', value: 'created_at'},
        ],
    }),

    methods: {
        getPaymentData: function () {
            Payment.index(this.form)
                .then((response) => {
                    this.paymentData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getPaymentData();
            },
            deep: true,
        },
    },
}
</script>
