import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/order", {
            params: form
        });
    },

    async order_count(form) {
        return Api.get("/order-count", {
            params: form
        });
    },

    async store(form, id) {
        return Api.post("/order/" + id, form);
    },

    async show(id) {
        return Api.post("/order/" + id);
    },
    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/order/" + id, form);
    },

    async delete(id) {
        return Api.delete("/order/" + id);
    },
};
