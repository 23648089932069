<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <v-card class="ma-3">
                    <v-card-title>Add Store</v-card-title>

                    <v-card-text>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitStoreForm"
                            ref="form"
                        >
                            <v-row>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.store_name"
                                        :rules="rules.store_name"
                                        label="Store Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.name"
                                        :rules="rules.name"
                                        label="Owner Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.email"
                                        :rules="rules.email"
                                        label="Email ID"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <VuePhoneNumberInput
                                        v-model="mobile"
                                        valid-color="#666666"
                                        default-country-code="IN"
                                        required
                                        v-on:update="getMobileData"
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.sub_domain"
                                        :rules="rules.sub_domain"
                                        label="Sub Domain"
                                        required
                                        v-on:keypress="isLetter($event)"
                                    ></v-text-field>
                                    {{ form.sub_domain }}.essenmart.com
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.domain"
                                        :rules="rules.domain"
                                        label="Domain"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.store_address"
                                        :rules="rules.store_address"
                                        required
                                        label="Store Address"
                                        counter
                                    ></v-textarea>
                                </v-col>

                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.display_address"
                                        :rules="rules.display_address"
                                        required
                                        label="Display Address"
                                        counter
                                    ></v-textarea>
                                </v-col>
                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.short_description"
                                        :rules="rules.short_description"
                                        required
                                        label="Short Description"
                                        counter="300"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col col="12" md="3">
                                    <v-file-input
                                        outlined
                                        :rules="rules.logo"
                                        v-model="files.logo"
                                        accept="image/png, image/jpeg, image/bmp, image/webp"
                                        placeholder="Choose Store Logo"
                                        prepend-icon="mdi-camera"
                                        label="Store Logo"
                                    ></v-file-input>
                                </v-col>

                                <v-col col="12" md="3">
                                    <v-file-input
                                        outlined
                                        :rules="rules.banner"
                                        v-model="files.banner"
                                        accept="image/png, image/jpeg, image/bmp, image/webp"
                                        placeholder="Pick an avatar"
                                        prepend-icon="mdi-camera"
                                        label="Store Banner"
                                    ></v-file-input>
                                </v-col>
                            </v-row>

                            <v-row class="justify-center ma-5">
                                <v-btn
                                    color="primary"
                                    type="submit"
                                    :loading="loading"
                                    :disabled="loading"
                                >Submit
                                </v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Store from "@/apis/Store";
import StoreUser from "@/apis/StoreUser";

export default {
    name: "AddStoreView",

    components: {
        VuePhoneNumberInput,
    },

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
    },

    data: () => ({
        valid: false,
        mobile: "",
        rules: {
            name: [(v) => !!v || "Store Name is required"],
            store_name: [(v) => !!v || "Store Name is required"],
            email: [
                (v) => !!v || "Email is required",
                (v) => /.+@.+/.test(v) || "E-mail must be valid",
            ],
            mobile: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            country_code: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            store_address: [(v) => !!v || "Store Address is required"],
            display_address: [(v) => !!v || "Display Address is required"],
            short_description: [(v) => !!v || "Short Description is required"],
            sub_domain: [(v) => !!v || "Sub Domain is required"],
            logo: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Store Logo size should be less than 2 MB!",
            ],
            banner: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Store Banner size should be less than 2 MB!",
            ],
        },
        loading: false,
        mobileRule: false,
        form: {
            name: "",
            store_name: "",
            email: "",
            mobile: "",
            country_code: "",
            store_address: "",
            display_address: "",
            short_description: "",
            store_id: null,
        },
        files: {
            logo: null,
            banner: null,
        },
    }),

    methods: {
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex
            else e.preventDefault(); // If not match, don't add to input text
        },

        getMobileData(val) {
            this.form.country_code = val.countryCallingCode;
            this.form.mobile = val.nationalNumber;
            this.mobileRule = val.isValid;
        },

        submitStoreForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.form) {
                    fd.append(element, this.form[element]);
                }

                for (var fileElement in this.files) {
                    fd.append(fileElement, this.files[fileElement]);
                }

                Store.store(fd)
                    .then((response) => {
                        this.form.store_id = response.data.data.id;
                        StoreUser.store(this.form).then(() => {
                            this.loading = false;
                            this.$refs.form.reset();

                            this.$notify({
                                title: "Success",
                                text: "Store Added !!",
                                type: "success",
                                duration: 10000,
                            });

                            this.$router.push("store-detail/" + this.form.store_id);
                        });
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },
};
</script>
