<template>
  <div class="about pa-10">
    <v-col class="col-md-12">
      <v-card>
        <v-card-text>
          <v-form v-model="valid" @submit.prevent="getCustomerData" ref="form">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Search"
                  v-model="form.search"
                  prepend-icon="mdi-card-search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="mt-5">
                <v-btn type="submit" small color="primary" dark> Search </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col class="col-md-12">
      <v-data-table
        :headers="headers"
        :items="customerData.data"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><h4>All Customers</h4></v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            Total :
            {{ customerData.meta ? customerData.meta.total : 0 }}
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item link :to="'customer-view/' + item.id">
                <v-list-item-title>
                  <v-icon small> mdi-eye </v-icon>&nbsp; View
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title v-on:click="$router.push('/' + item.id)">
                  <v-icon small> mdi-delete </v-icon>&nbsp; Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getCustomerData"> Reset </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>

<script>
import Customer from "@/apis/Customer";

export default {
  name: "CustomerListView",

  data: () => ({
    valid: false,
    loading: true,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },

      { text: "Email address", value: "email" },
      { text: "Phone", value: "mobile" },
      //Todo: Check for Order Count
      { text: "Number of Orders", value: "order_count" },
      { text: "Store Name", value: "store.store_name" },
      { text: "Options", value: "actions", sortable: false },
    ],
    form: {
      page: 1,
      search: "",
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    options: {},
    total: 0,
    customerData: [],
  }),

  mounted() {
    this.$parent.$parent.$parent.selectedItem = 4;
  },

  methods: {
    getCustomerData() {
      Customer.index(this.form)
        .then((response) => {
          this.customerData = response.data;
          this.loading = false;
          this.total = response.data.meta.total;
        })
        .catch((error) => {
          this.loading = false;

          var text = error.response.data.message;
          if (error.response.data.errors) {
            for (var value in error.response.data.errors) {
              if (text) text += "<br>";
              text += error.response.data.errors[value];
            }
          }

          this.$notify({
            title: "Error",
            text: text,
            type: "error",
            duration: 10000,
          });
        });
    },
  },
  watch: {
    options: {
      handler(val) {
        this.form.page = val.page;
        this.form.itemsPerPage = val.itemsPerPage;
        this.form.sortBy = val.sortBy;
        this.form.sortDesc = val.sortDesc;
        this.getCustomerData();
      },
      deep: true,
    },
  },
};
</script>
