<template>
    <div class="about pa-10">
        <v-col class="col-md-12">
            <v-card class="ma-3">
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Search"
                                prepend-icon="mdi-card-search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn small color="primary" dark> Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="col-md-12">
            <v-data-table
                :headers="headers"
                :items="storeData.data"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Store</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-toolbar-title
                        >Total :
                            {{ storeData.meta ? storeData.meta.total : 0 }}
                        </v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn to="add-store" color="primary"> Add New Store</v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:item.store_name="{ item }">
                    <router-link :to="'store-detail/' + item.id">
                        {{ item.store_name }}
                    </router-link>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-switch
                        v-model="item.status"
                        v-on:change="storeStatusChange(item)"
                    ></v-switch>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="primary" dark v-bind="attrs" v-on="on">
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item link :to="'store-detail/' + item.id">
                                <v-list-item-title>
                                    <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item link v-on:click="$router.push('add-store/' + item.id)">
                                <v-list-item-title>
                                    <v-icon small> mdi-delete</v-icon>&nbsp; Delete
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <template v-slot:no-data>
                    <v-btn color="primary"> Reset</v-btn>
                </template>
            </v-data-table>
        </v-col>
    </div>
</template>

<script>
import Store from "@/apis/Store";

export default {
    name: "StoreView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
    },

    data: () => ({
        dates: [
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
        ],
        menu: false,
        modal: false,
        menu2: false,
        storeData: [],
        form: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
        },
        options: {},
        total: 0,
        loading: true,
        headers: [
            {
                text: "Store Name",
                align: "start",
                value: "store_name",
            },
            {text: "Store Address", value: "store_address"},
            {text: "Phone", value: "mobile"},
            {text: "Email", value: "email"},
            {text: "Status", value: "status", sortable: false},
            {text: "Action", value: "actions", sortable: false},
        ],
    }),

    methods: {
        getStoreData: function () {
            Store.index(this.form)
                .then((response) => {
                    this.storeData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        storeStatusChange: function (store) {
            Store.changeStatus(store.id)
                .then((response) => {
                    store.status = response.data.data.status;

                    this.$notify({
                        title: "Success",
                        text: "Store Information Updated !!",
                        type: "success",
                        duration: 10000,
                    });
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    },

    watch: {
        options: {
            handler(val) {
                this.form.page = val.page;
                this.form.itemsPerPage = val.itemsPerPage;
                this.form.sortBy = val.sortBy;
                this.form.sortDesc = val.sortDesc;
                this.getStoreData();
            },
            deep: true,
        },
    },
};
</script>
