var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pa-10"},[_c('v-col',{staticClass:"col-md-12"},[_c('v-card',{staticClass:"ma-3"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Search","prepend-icon":"mdi-card-search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"small":"","color":"primary","dark":""}},[_vm._v(" Submit")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.storeData.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Store")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',[_vm._v("Total : "+_vm._s(_vm.storeData.meta ? _vm.storeData.meta.total : 0)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"add-store","color":"primary"}},[_vm._v(" Add New Store")])],1)]},proxy:true},{key:"item.store_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'store-detail/' + item.id}},[_vm._v(" "+_vm._s(item.store_name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.storeStatusChange(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":'store-detail/' + item.id}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil")]),_vm._v("  Edit ")],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$router.push('add-store/' + item.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete")]),_vm._v("  Delete ")],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }