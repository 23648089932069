<template>
    <div class="about pa-10">
        <v-col cols="12" md="12">
            <v-btn v-on:click="showFileForm = !showFileForm">Upload New File</v-btn>

            <v-card
                v-show="showFileForm"
                class="mt-10"
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                :class="{ 'grey lighten-2': dragover }"
                :loading="loading"
            >
                <v-card-text>
                    <v-form v-model="valid" ref="form" v-if="isInitial || isSaving">
                        <div class="dropbox">
                            <v-file-input
                                outlined
                                multiple
                                v-model="uploadedFiles"
                                placeholder="Choose Store Logo"
                                prepend-icon="mdi-camera"
                                label="Upload File"
                                class="input-file"
                                @change="uploadFileForm"
                            ></v-file-input>
                            <p v-if="isInitial">
                                Drag your file(s) here to begin<br/>
                                or click to browse
                            </p>
                            <p v-if="isSaving">Uploading {{ fileCount }} files...</p>
                        </div>
                    </v-form>

                    <!--SUCCESS-->
                    <div v-if="isSuccess">
                        <h2>Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
                        <p>
                            <a href="javascript:void(0)" @click="reset()">Upload again</a>
                        </p>
                        <ul class="list-unstyled">
                            <li v-for="(item, key) in uploadedFiles" :key="key">
                                <img
                                    :src="item.url"
                                    class="img-responsive img-thumbnail"
                                    :alt="item.originalName"
                                />
                            </li>
                        </ul>
                    </div>
                    <!--FAILED-->
                    <div v-if="isFailed">
                        <h2>Uploaded failed.</h2>
                        <p>
                            <a href="javascript:void(0)" @click="reset()">Try again</a>
                        </p>
                        <pre>{{ uploadError }}</pre>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col class="col-md-12">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Search"
                                prepend-icon="mdi-card-search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                            <v-btn small color="primary" dark> Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-row>
                <v-col
                    v-for="(files, index) in uploadedFileData.data"
                    :key="index"
                    cols="12"
                    md="2"
                >
                    <v-card v-on:click="fileDetail(files)">
                        <template slot="progress">
                            <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                        </template>

                        <v-img :src="files.url" width="250" height="150"></v-img>
                        <v-card-text>
                            <strong> {{ files.alternative_text }}</strong>
                            <br/>
                            {{ files.size }}
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-pagination
                    v-model="form.page"
                    :length="last_page"
                    :total-visible="7"
                    @input="getUploadedFile"
                ></v-pagination>
            </v-row>
        </v-col>

        <v-dialog
            v-model="dialog"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card :loading="loading">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        {{ singleFileDetail.alternative_text }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="updateFileForm"> Save</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-row class="mt-5 ml-5 mr-5">
                    <v-col cols="12" md="6">
                        <v-row>
                            <div class="subtitle-2">File Name:</div>
                            <div class="body-1">
                                &nbsp;{{ singleFileDetail.alternative_text }}
                            </div>
                        </v-row>
                        <v-row>
                            <div class="subtitle-2">File Type:</div>
                            <div class="body-1">&nbsp;{{ singleFileDetail.mime }}</div>
                        </v-row>
                        <v-row>
                            <div class="subtitle-2">Uploaded On:</div>
                            <div class="body-1">
                                &nbsp;{{ singleFileDetail.created_at_diff }}
                            </div>
                        </v-row>
                        <v-row>
                            <div class="subtitle-2">File Size:</div>
                            <div class="body-1">&nbsp;{{ singleFileDetail.size }}</div>
                        </v-row>
                        <v-divider class="mt-5 mb-5"></v-divider>

                        <v-form
                            v-model="valid"
                            @submit.prevent="updateFileForm"
                            ref="updateForm"
                        >
                            <v-col>
                                <v-text-field
                                    outlined
                                    v-model="updateForm.alternative_text"
                                    :rules="rules.alternative_text"
                                    label="Alternative Text"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    outlined
                                    v-model="singleFileDetail.url"
                                    label="Copy URL"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img :src="singleFileDetail.url"></v-img>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UploadedFiles from "@/apis/UploadedFiles";

const STATUS_INITIAL = 0,
    STATUS_SAVING = 1,
    STATUS_SUCCESS = 2,
    STATUS_FAILED = 3;

export default {
    name: "UploadFilesView",

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 7;
        this.reset();
        this.getUploadedFile();
    },

    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        },
    },

    data: () => ({
        valid: false,

        dialog: false,
        showFileForm: false,
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        dragover: false,
        multiple: true,
        fileCount: 0,
        uploadedFileData: [],
        singleFileDetail: [],

        form: {
            page: 1,
            itemsPerPage: 24,
            sortBy: [],
            sortDesc: [],
        },
        updateForm: {
            alternative_text: "",
        },
        rules: {
            alternative_text: [(v) => !!v || "Alternative Text is required"],
        },
        options: {},
        total: 0,
        loading: true,
        last_page: 1,
    }),

    methods: {
        getUploadedFile() {
            UploadedFiles.index(this.form)
                .then((response) => {
                    this.uploadedFileData = response.data;
                    this.loading = false;
                    this.total = response.data.meta.total;
                    this.form.page = response.data.meta.current_page;
                    this.last_page = response.data.meta.last_page;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },

        uploadFileForm() {
            // upload data to the server
            this.currentStatus = STATUS_SAVING;

            const fd = new FormData();

            this.uploadedFiles.forEach((value) => {
                fd.append("file[]", value);
            });

            UploadedFiles.store(fd)
                .then(() => {
                    this.$notify({
                        title: "Success",
                        text: "File Uploaded !!",
                        type: "success",
                        duration: 10000,
                    });

                    this.currentStatus = STATUS_SUCCESS;
                    this.loading = false;
                    this.reset();
                    this.getUploadedFile();
                })
                .catch((error) => {
                    this.currentStatus = STATUS_INITIAL;
                    this.reset();

                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    this.loading = false;
                });
        },

        onDrop(e) {
            this.dragover = false;
            // If there are already uploaded files remove them
            // if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
            // If user has uploaded multiple files but the component is not multiple throw error
            if (!this.multiple && e.dataTransfer.files.length > 1) {
                this.$store.dispatch("addNotification", {
                    message: "Only one file can be uploaded at a time..",
                    colour: "error",
                });
            }
            // Add each file to the array of uploaded files
            else {
                for (var element of e.dataTransfer.files) {
                    if (!element.isFile) this.uploadedFiles.push(element);
                }
                this.uploadFileForm();
            }
        },

        updateFileForm() {
            if (this.$refs.updateForm.validate()) {
                this.loading = true;

                UploadedFiles.update(this.updateForm, this.singleFileDetail.id)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "File Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getUploadedFile();

                        this.loading = false;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                        }

                        this.loading = false;
                    });
            }
        },

        fileDetail(files) {
            this.dialog = !this.dialog;
            this.singleFileDetail = files;
            this.updateForm.alternative_text = files.alternative_text;
        },
    },

    watch: {
        uploadedFiles: function ($val) {
            this.fileCount = $val.length;
        },
    },
};
</script>
