<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <StoreHeadingComponent :id="id"/>

                <v-form v-model="valid" @submit.prevent="submitStoreForm" ref="form">
                    <v-card class="mt-3" :loading="loading">
                        <v-card-title>Store Detail</v-card-title>

                        <v-card-text>
                            <v-row>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model="form.id"
                                        label="Store ID"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.store_name"
                                        :rules="rules.store_name"
                                        label="Store Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        v-model="form.email"
                                        :rules="rules.email"
                                        label="Email ID"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                    <VuePhoneNumberInput
                                        v-model="mobile"
                                        valid-color="#666666"
                                        default-country-code="IN"
                                        required
                                        v-on:update="getMobileData"
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model="form.sub_domain"
                                        label="Sub Domain"
                                        required
                                        v-on:keypress="isLetter($event)"
                                    ></v-text-field>
                                    {{ form.sub_domain }}.essenmart.com
                                </v-col>
                                <v-col col="12" md="3">
                                    <v-text-field
                                        outlined
                                        readonly
                                        v-model="form.domain"
                                        label="Domain"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.store_address"
                                        :rules="rules.store_address"
                                        required
                                        label="Store Address"
                                        counter
                                    ></v-textarea>
                                </v-col>

                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.display_address"
                                        :rules="rules.display_address"
                                        required
                                        label="Display Address"
                                        counter
                                    ></v-textarea>
                                </v-col>
                                <v-col col="12" md="4">
                                    <v-textarea
                                        outlined
                                        no-resize
                                        rows="3"
                                        v-model="form.short_description"
                                        :rules="rules.short_description"
                                        required
                                        label="Short Description"
                                        counter="300"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col col="12" md="3">
                                    <v-file-input
                                        outlined
                                        :rules="rules.logo"
                                        v-model="files.logo"
                                        accept="image/png, image/jpeg, image/bmp, image/webp"
                                        placeholder="Choose Store Logo"
                                        prepend-icon="mdi-camera"
                                        label="Store Logo"
                                    ></v-file-input>
                                    <a target="_blank" :href="storeData.logo" class="ml-10">
                                        View Logo
                                    </a>
                                </v-col>

                                <v-col col="12" md="3">
                                    <v-file-input
                                        outlined
                                        :rules="rules.banner"
                                        v-model="files.banner"
                                        accept="image/png, image/jpeg, image/bmp, image/webp"
                                        placeholder="Pick an avatar"
                                        prepend-icon="mdi-camera"
                                        label="Store Banner"
                                    ></v-file-input>
                                    <a target="_blank" :href="storeData.banner" class="ml-10">
                                        View Banner
                                    </a>
                                </v-col>
                            </v-row>

                            <v-card-text>
                                <v-row class="justify-center ma-5">
                                    <v-btn
                                        color="primary"
                                        type="submit"
                                        :loading="loading"
                                        :disabled="loading"
                                    >Save
                                    </v-btn>
                                </v-row>
                            </v-card-text>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-title>Payment Detail</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-checkbox
                                    class="ma-5"
                                    v-model="paymentForm.payment_gateway"
                                    label="RazorPay"
                                    value="1"
                                ></v-checkbox>
                            </v-row>

                            <v-row>
                                <v-col col="12" md="3">
                                <v-text-field
                                    outlined
                                    v-model="paymentForm.keyId"
                                    label="RazorPay Key ID"
                                    required
                                ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3">
                                <v-text-field
                                    outlined
                                    v-model="paymentForm.keySecret"
                                    label="RazorPay Secret Key"
                                    required
                                ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            <v-row class="justify-center ma-5">
                                <v-btn
                                    color="primary"
                                    type="button"
                                    :loading="loading"
                                    :disabled="loading"
                                    v-on:click="submitStorePaymentGatewayForm"
                                >Save
                                </v-btn>
                            </v-row>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-title>Tax Detail</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col col="12" md="3">
                                    <v-checkbox
                                        class="ma-5"
                                        v-model="taxForm.have_gst"
                                        label="Have GST"
                                        false-value="N"
                                        true-value="Y"
                                    ></v-checkbox>
                                </v-col>

                                <v-col col="12" md="3" v-show="taxForm.have_gst == 'Y'">
                                    <v-text-field
                                        outlined
                                        v-model="taxForm.business_name"
                                        label="Business Name"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3" v-show="taxForm.have_gst == 'Y'">
                                    <v-text-field
                                        outlined
                                        v-model="taxForm.gst_number"
                                        label="GST Number"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col col="12" md="3" v-show="taxForm.have_gst == 'Y'">
                                    <v-text-field
                                        outlined
                                        v-model="taxForm.gst_address"
                                        label="GST Address"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text>
                            <v-row class="justify-center ma-5">
                                <v-btn
                                    color="primary"
                                    type="button"
                                    :loading="loading"
                                    :disabled="loading"
                                    v-on:click="submitStoreTaxForm"
                                >Save
                                </v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Store from "@/apis/Store";
import PaymentGateway from "@/apis/PaymentGateway";
import StorePaymentGateway from "@/apis/StorePaymentGateway";
import StoreHeadingComponent from "../../components/StoreHeadingComponent";

export default {
    name: "StoreDetailView",

    components: {
        StoreHeadingComponent,
        VuePhoneNumberInput,
    },

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.id = this.$route.params.id;
        this.paymentForm.store_id = this.$route.params.id;
        this.getStoreDetail();
        this.getPaymentGateway();
    },

    data: () => ({
        id: null,
        loading: false,
        storeData: [],
        paymentGatewayData: [],
        valid: false,
        mobile: "",
        rules: {
            name: [(v) => !!v || "Store Name is required"],
            store_name: [(v) => !!v || "Store Name is required"],
            email: [
                (v) => !!v || "Email is required",
                (v) => /.+@.+/.test(v) || "E-mail must be valid",
            ],
            mobile: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            country_code: [
                (v) => !!v || "Mobile is required",
                (v) => /^(\+\d{1,3}[- ]?)?\d{10}$/.test(v) || "Mobile must be valid",
            ],
            store_address: [(v) => !!v || "Store Address is required"],
            display_address: [(v) => !!v || "Display Address is required"],
            short_description: [(v) => !!v || "Short Description is required"],
            logo: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Store Logo size should be less than 2 MB!",
            ],
            banner: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Store Banner size should be less than 2 MB!",
            ],
        },
        mobileRule: false,
        form: {
            name: "",
            store_name: "",
            email: "",
            mobile: "",
            country_code: "",
            store_address: "",
            display_address: "",
            short_description: "",
            id: null,
            store_id: null,
            sub_domain: "",
            domain: "",
        },
        paymentForm: {
            store_id: null,
            payment_gateway: "",
            keyId: "",
            keySecret: "",
        },
        taxForm: {
            have_gst: "N",
            business_name: "",
            gst_number: "",
            gst_address: "",
        },
        files: {
            logo: null,
            banner: null,
        },
    }),

    methods: {
        isLetter(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex
            else e.preventDefault(); // If not match, don't add to input text
        },

        getStoreDetail: function () {
            this.loading = true;
            Store.show(this.id)
                .then((response) => {
                    this.storeData = response.data.data;

                    this.form.id = "ESSMRT-" + this.storeData.id;
                    this.form.store_id = this.storeData.id;
                    this.form.store_name = this.storeData.store_name;
                    this.form.email = this.storeData.email;
                    this.form.mobile = this.storeData.mobile;
                    this.mobile = this.storeData.mobile.toString();
                    this.form.country_code = this.storeData.country_code;
                    this.form.store_address = this.storeData.store_address;
                    this.form.display_address = this.storeData.display_address;
                    this.form.short_description = this.storeData.short_description;
                    this.form.sub_domain = this.storeData.sub_domain;
                    this.form.domain = this.storeData.domain;

                    /*if (this.storeData.payment_gateway) {
                        this.storeData.payment_gateway.forEach((value, index) => {
                            this.paymentForm.payment_gateway[index] = value.id;
                        });
                    }*/

                    this.paymentForm.store_id = this.storeData.id
                    this.paymentForm.payment_gateway = this.storeData.store_payment_gateway.payment_gateway_id.toString();
                    this.paymentForm.keyId = this.storeData.store_payment_gateway.payment_gateway_keys.keyId;
                    this.paymentForm.keySecret = this.storeData.store_payment_gateway.payment_gateway_keys.keySecret;

                    this.taxForm.have_gst = this.storeData.have_gst;
                    this.taxForm.business_name = this.storeData.business_name;
                    this.taxForm.gst_number = this.storeData.gst_number;
                    this.taxForm.gst_address = this.storeData.gst_address;

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getPaymentGateway: function () {
            this.loading = true;
            PaymentGateway.index()
                .then((response) => {
                    this.paymentGatewayData = response.data.data;

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getMobileData(val) {
            this.form.country_code = val.countryCallingCode;
            this.form.mobile = val.nationalNumber;
            this.mobileRule = val.isValid;
        },

        submitStoreForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                fd.append("name", this.form.name);
                fd.append("store_name", this.form.store_name);
                fd.append("email", this.form.email);
                fd.append("mobile", this.form.mobile);
                fd.append("country_code", this.form.country_code);
                fd.append("store_address", this.form.store_address);
                fd.append("display_address", this.form.display_address);
                fd.append("short_description", this.form.short_description);
                fd.append("logo", this.files.logo);
                fd.append("banner", this.files.banner);

                Store.update(fd, this.id)
                    .then((response) => {
                        this.storeData = response.data.data;

                        this.form = {};

                        this.form.id = "ESSMRT-" + this.storeData.id;
                        this.form.store_id = this.storeData.id;
                        this.form.store_name = this.storeData.store_name;
                        this.form.email = this.storeData.email;
                        this.form.mobile = this.storeData.mobile;
                        this.mobile = this.storeData.mobile;
                        this.form.country_code = this.storeData.country_code;
                        this.form.store_address = this.storeData.store_address;
                        this.form.display_address = this.storeData.display_address;
                        this.form.short_description = this.storeData.short_description;

                        this.loading = false;

                        this.$notify({
                            title: "Success",
                            text: "Store Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        submitStoreTaxForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                Store.updateTax(this.taxForm, this.id)
                    .then((response) => {
                        this.storeData = response.data.data;

                        this.taxForm = {};

                        this.taxForm.have_gst = this.storeData.have_gst;
                        this.taxForm.business_name = this.storeData.business_name;
                        this.taxForm.gst_number = this.storeData.gst_number;
                        this.taxForm.gst_address = this.storeData.gst_address;

                        this.loading = false;

                        this.$notify({
                            title: "Success",
                            text: "Store Tax Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },

        submitStorePaymentGatewayForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                StorePaymentGateway.store(this.paymentForm)
                    .then(() => {
                        this.$notify({
                            title: "Success",
                            text: "Payment Gateway Updated !!",
                            type: "success",
                            duration: 10000,
                        });
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },
};
</script>
