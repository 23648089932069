<template>
    <div class="about pa-10">
        <v-row class="mb-5 flex-row justify-start">
            <v-col class="col-md-4">
                <v-card class="pa-3">
                    <v-card-text>
                        <p class="text-h5 text--success">
                            <v-icon color="success">mdi-cash-multiple</v-icon>&nbsp;Sales (in ₹)
                        </p>
                        <v-row class="mt-5">
                            <v-col class="mr-10">
                                <v-divider class="mb-5" style="color: blue"></v-divider>
                                <p class="text--success mb-5">
                                    Today
                                </p>
                                <p class="text-h6 text--success mb-5">
                                    ₹ 0.00
                                </p>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="ml-10">
                                <v-divider class="mb-5"></v-divider>
                                <p class="text--success mb-5">
                                    Total
                                </p>
                                <p class="text-h6 text--success mb-5">
                                    ₹ 0.00
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-4">
                <v-card class="pa-3" :loading="loading">
                    <v-card-text>
                        <p class="text-h5 text--success">
                            <v-icon color="primary">mdi-account-multiple-outline</v-icon>&nbsp;Customer
                        </p>
                        <v-row class="mt-5">
                            <v-col class="mr-10">
                                <v-divider class="mb-5" style="color: blue"></v-divider>
                                <p class="text--success mb-5">
                                    Today
                                </p>
                                <p class="text-h6 text--success mb-5">
                                    {{ dashboard.todayCustomer }}
                                </p>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col class="ml-10">
                                <v-divider class="mb-5"></v-divider>
                                <p class="text--success mb-5">
                                    Total
                                </p>
                                <p class="text-h6 text--success mb-5">
                                    {{ dashboard.totalCustomer }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-4">
                <v-card class="pa-3" :loading="loading">
                    <v-card-text>
                        <p class="text-h5 text--success">
                            <v-icon color="warning">mdi-store</v-icon>&nbsp;Total Stores
                        </p>
                        <v-row class="mt-5">
                            <v-col>
                                <v-divider class="mb-5"></v-divider>
                                <p class="text--success mb-5">
                                    Total
                                </p>
                                <p class="text-h6 text--success mb-5">
                                    {{ dashboard.totalStore }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="flex-row justify-start">
            <v-col class="col-md-3">
                <v-card class="mb-3 pa-3">
                    <v-card-text>
                        <v-col class="mr-10">
                            <p class="text-h6 text--success mb-5">
                                Orders Stats
                            </p>
                            <p class="text--success mb-5">
                                <router-link :to="{name:'orders'}" class="nav-link">View All
                                    <v-icon color="primary">mdi-arrow-right-thin</v-icon>
                                </router-link>
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
                <v-row>
                    <v-col class="col-md-6">
                        <v-card :loading="loading">
                            <v-card-text>
                                <v-col class="mr-10">
                                    <p class="text-h6 text--success mb-5">
                                        {{ dashboard.totalCategory }}
                                    </p>
                                    <p class="text--success mb-5">
                                        <v-icon color="primary">mdi-shape</v-icon>&nbsp;Total Category
                                    </p>
                                </v-col>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="col-md-6">
                        <v-card :loading="loading">
                            <v-card-text>
                                <v-col class="mr-10">
                                    <p class="text-h6 text--success mb-5">
                                        {{ dashboard.totalBrand }}
                                    </p>
                                    <p class="text--success mb-5">
                                        <v-icon color="primary">mdi-watermark</v-icon>&nbsp;Total Brands
                                    </p>
                                </v-col>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <!--          <v-col class="col-md-6">
                                <v-card>
                                  <v-card-text>
                                    <v-col class="mr-10">
                                      <p class="text-h6 text&#45;&#45;success mb-5">
                                        0
                                      </p>
                                      <p class="text&#45;&#45;success mb-5">
                                        <v-icon color="primary">mdi-ticket-percent</v-icon>&nbsp;Total Coupons
                                      </p>
                                    </v-col>
                                  </v-card-text>
                                </v-card>
                              </v-col>
                              <v-col class="col-md-6">
                                <v-card>
                                  <v-card-text>
                                    <v-col class="mr-10">
                                      <p class="text-h6 text&#45;&#45;success mb-5">
                                        0
                                      </p>
                                      <p class="text&#45;&#45;success mb-5">
                                        <v-icon color="primary">mdi-tag</v-icon>&nbsp;Total Offers
                                      </p>
                                    </v-col>
                                  </v-card-text>
                                </v-card>
                              </v-col>-->
                </v-row>
            </v-col>
            <v-col class="col-md-3">
                <v-card class="mb-3">
                    <v-card-text>
                        <v-col>
                            <p class="text-h6 text--success">
                                ₹ {{ order_count.completedOrder }}
                            </p>
                            <p class="text--success">
                                <v-icon color="success">mdi-store-check</v-icon>&nbsp;Completed
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-text>
                        <v-col>
                            <p class="text-h6 text--success">
                                ₹ {{ order_count.dispatchedOrder }}
                            </p>
                            <p class="text--primary">
                                <v-icon color="primary">mdi-truck</v-icon>&nbsp;Dispatched
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-text>
                        <v-col>
                            <p class="text-h6 text--success">
                                ₹ {{ order_count.pendingOrder }}
                            </p>
                            <p class="text--success">
                                <v-icon color="warning">mdi-account-clock</v-icon>&nbsp;Pending
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-text>
                        <v-col>
                            <p class="text-h6 text--success">
                                ₹ {{ order_count.cancelledOrder }}
                            </p>
                            <p class="text--success">
                                <v-icon color="error">mdi-close-circle-outline</v-icon>&nbsp;Cancelled
                            </p>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-3">
                <v-card>
                    <v-card-title>Top Category</v-card-title>
                    <v-card-text>
                        <v-col>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                    <tr v-for="(category,key) in dashboard.topCategory" :key="key">
                                        <td>
                                            <v-img max-width="50"
                                                   :src="category.banner_file.url"></v-img>
                                        </td>
                                        <td>{{ category.category.name }}</td>
                                        <td>₹ {{ category.amount }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-md-3">
                <v-card>
                    <v-card-title>Top Brands</v-card-title>
                    <v-card-text>
                        <v-col>
                            <v-simple-table>
                                <template v-slot:default>
                                    <tbody>
                                    <tr v-for="(brand,key) in dashboard.topBrand" :key="key">
                                        <td>
                                            <v-img max-width="50"
                                                   :src="brand.logo_file.url"></v-img>
                                        </td>
                                        <td>{{ brand.brand.name }}</td>
                                        <td>₹ {{ brand.amount }}</td>
                                    </tr>

                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Dashboard from "@/apis/Dashboard";
import Order from "@/apis/Order";

export default {
    name: 'Dashboard',

    mounted() {
        this.$parent.selectedItem = 0;
        this.getDashboardData();
    },

    data: () => ({
        dashboard: {},
        order_count: {},
        loading: false,
    }),

    methods: {
        getDashboardData: function () {
            this.loading = true;
            Dashboard.index()
                .then((response) => {
                    this.dashboard = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });

            Order.order_count()
                .then((response) => {
                    this.order_count = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },
    }
}
</script>
