import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/product-variant", {
            params: form
        });
    },

    async store(form) {
        return Api.post("/product-variant", form);
    },

    async show(id) {
        return Api.post("/product-variant/" + id);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/product-variant/" + id, form);
    },

    async delete(id) {
        return Api.delete("/product-variant/" + id);
    },
};
