<template>
    <div class="about pa-10">
        <v-row class="flex-row justify-start">
            <v-col class="col-md-12 pa-3">
                <StoreHeadingComponent :active="2" :id="store_id"/>
            </v-col>
            <!--   category-->
            <v-col cols="3">
                <v-card :loading="loading" class="mx-auto">
                    <v-toolbar elevation="1">
                        <v-toolbar-title>Category({{ categoryTotal }})</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="categoryDialog = true">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-list>
                            <v-list-item-group
                                v-model="categorySelected"
                                active-class="blue--text"
                                mandatory
                            >
                                <template v-for="(category, index) in categoryData.data">
                                    <v-list-item :key="category.name">
                                        <v-list-item-content
                                            @click="clickCategoryButton(category, 'parent')"
                                        >
                                            <v-list-item-title
                                                v-text="category.name"
                                            ></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">
                                                        mdi-dots-vertical
                                                    </v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="editCategoryDetail(category)">
                                                        <v-list-item-title>
                                                            <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item>
                                                      <v-list-item-title>
                                                        <v-icon> mdi-trash-can </v-icon>&nbsp; Delete
                                                      </v-list-item-title>
                                                    </v-list-item> -->
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < categoryData.data.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <!--  sub category-->
            <v-col cols="3" v-show="subCategoryTotal > 0">
                <v-card :loading="loading" class="mx-auto">
                    <v-toolbar elevation="1">
                        <v-toolbar-title>
                            Sub Category({{ subCategoryTotal }})
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-list>
                            <v-list-item-group
                                v-model="subCategorySelected"
                                active-class="blue--text"
                                mandatory
                            >
                                <template v-for="(category, index) in subCategoryData.data">
                                    <v-list-item :key="category.name">
                                        <v-list-item-content
                                            @click="clickCategoryButton(category, 'child')"
                                        >
                                            <v-list-item-title
                                                v-text="category.name"
                                            ></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">
                                                        mdi-dots-vertical
                                                    </v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click="editCategoryDetail(category)">
                                                        <v-list-item-title>
                                                            <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item>
                                                      <v-list-item-title>
                                                        <v-icon> mdi-trash-can </v-icon>&nbsp; Delete
                                                      </v-list-item-title>
                                                    </v-list-item> -->
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < subCategoryData.data.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- product-->
            <v-col cols="3">
                <v-card :loading="loading" class="mx-auto">
                    <v-toolbar elevation="1">
                        <v-toolbar-title>Product({{ productTotal }})</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon :to="{ name: 'add-product' }">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-list v-show="productTotal > 0">
                            <v-list-item-group
                                v-model="subCategorySelected"
                                active-class="blue--text"
                                mandatory
                            >
                                <template v-for="(product, index) in productData.data">
                                    <v-list-item :key="product.product_name">
                                        <v-list-item-content @click="getProductDetail(product)">
                                            <v-list-item-title
                                                v-text="product.product_name"
                                            ></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on">
                                                        mdi-dots-vertical
                                                    </v-icon>
                                                </template>
                                                <v-list>
                                                    <v-list-item :to="'/add-product/' + product.id">
                                                        <v-list-item-title>
                                                            <v-icon small> mdi-pencil</v-icon>&nbsp; Edit
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <!-- <v-list-item>
                                                      <v-list-item-title>
                                                        <v-icon> mdi-trash-can </v-icon>&nbsp; Delete
                                                      </v-list-item-title>
                                                    </v-list-item> -->
                                                </v-list>
                                            </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>

                                    <v-divider
                                        v-if="index < productData.data.length - 1"
                                        :key="index"
                                    ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>

                        <p v-show="productTotal == 0">No Products added to this category</p>
                    </v-card-text>
                </v-card>
            </v-col>

            <!--      products Details-->
            <v-col cols="3" v-if="productDetail">
                <v-card :loading="loading" class="mx-auto">
                    <v-toolbar elevation="1">
                        <v-toolbar-title>Product Details</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-list-item three-line>
                        <v-list-item-avatar class="ma-3" size="90" tile>
                            <v-img contain :src="productDetail.thumbnail_file.url"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                {{ productDetail.product_name }} -
                                {{ productDetail.product_unit }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-h6 mb-1"
                            >Rs - {{ productDetail.price }}
                            </v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>

                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title> Brand</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ productDetail.brand.name }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="productDetail.description"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- <v-list-item>
                          <v-list-item-title class="text-h8">
                            Variants/Add Ones
                          </v-list-item-title>

                          <v-spacer></v-spacer>
                          <v-list-item-title class="text-h9">
                            <a href="">Add</a>
                          </v-list-item-title>
                        </v-list-item> -->
                    </v-list>

                    <v-list></v-list>
                </v-card>
            </v-col>
        </v-row>

        <!--add category popup start-->
        <v-dialog v-model="categoryDialog" max-width="50%">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Add Category</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form
                            v-model="valid"
                            @submit.prevent="submitCategoryForm"
                            ref="form"
                        >
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Category Name"
                                        :rules="rules.name"
                                        v-model="form.name"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        clearable
                                        class="ml-3"
                                        :items="parentCategoryData"
                                        item-text="name"
                                        item-value="id"
                                        label="Parent Category"
                                        v-model="form.category_id"
                                        outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Order Level"
                                        :rules="rules.priority"
                                        v-model="form.priority"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Banner"
                                        :rules="rules.banner"
                                        v-model="files.banner"
                                    ></v-file-input>
                                    <a
                                        target="_blank"
                                        v-if="categoryDetail.banner"
                                        :href="categoryDetail.banner_file.url"
                                        class="ml-10"
                                    >
                                        View Banner
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Title"
                                        :rules="rules.meta_title"
                                        v-model="form.meta_title"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Meta Description"
                                        :rules="rules.meta_description"
                                        v-model="form.meta_description"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        class="ml-3"
                                        label="Slug"
                                        :rules="rules.slug"
                                        v-model="form.slug"
                                        outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-file-input
                                        outlined
                                        label="Meta Image"
                                        :rules="rules.meta_image"
                                        v-model="files.meta_image"
                                    ></v-file-input>
                                    <a
                                        target="_blank"
                                        v-if="categoryDetail.meta_image"
                                        :href="categoryDetail.meta_image_file.url"
                                        class="ml-10"
                                    >
                                        View Banner
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="text-right">
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn depressed @click="categoryDialog = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            depressed
                                            color="primary"
                                            :loading="loading"
                                            :disabled="loading"
                                            type="submit"
                                        >
                                            Submit
                                        </v-btn>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!--add category popup end-->
    </div>
</template>

<script>
import StoreHeadingComponent from "../../components/StoreHeadingComponent";
import Category from "@/apis/Category";
import Product from "@/apis/Product";

export default {
    name: "StoreCatalogueView",

    components: {
        StoreHeadingComponent,
    },

    mounted() {
        this.$parent.$parent.$parent.selectedItem = 2;
        this.store_id = this.$route.params.id;
        this.categorySearch.store_id = this.$route.params.id;
        this.subCategorySearch.store_id = this.$route.params.id;
        this.productSearch.store_id = this.$route.params.id;
        this.form.store_id = this.$route.params.id;

        this.getCategoryParentData();
        this.getCategoryData();
    },

    data: () => ({
        dialog: false,
        categoryDialog: false,
        valid: false,
        loading: false,
        store_id: null,

        categoryData: [],
        categoryTotal: 0,

        subCategoryData: [],
        subCategoryTotal: 0,

        productData: [],
        product_id: null,
        productDetail: null,
        productTotal: 0,

        categorySearch: {
            store_id: null,
        },
        categorySelected: 0,

        subCategorySearch: {
            store_id: null,
            category_id: "",
        },
        subCategorySelected: 0,

        productSearch: {
            store_id: null,
            category_id: "",
        },
        productSelected: 0,

        rules: {
            name: [(v) => !!v || "Category Name is required"],
            priority: [(v) => !!v || "Priority is required"],
            meta_title: [(v) => !!v || "Meta Title is required"],
            meta_description: [(v) => !!v || "Meta Description is required"],
            slug: [(v) => !!v || "Slug is required"],
            banner: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Banner size should be less than 2 MB!",
            ],
            meta_image: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Meta Image size should be less than 2 MB!",
            ],
        },
        parentCategoryData: [],
        storeOption: [],
        form: {
            name: "",
            category_id: "",
            priority: "",
            meta_title: "",
            meta_description: "",
            slug: "",
            id: "",
            store_id: "",
        },
        files: {
            meta_image: null,
            banner: null,
        },
        categoryDetail: [],
    }),

    methods: {
        getCategoryParentData: function () {
            this.loading = true;
            Category.get_parent(this.categorySearch)
                .then((response) => {
                    this.categoryData = response.data;
                    this.loading = false;
                    this.categoryTotal = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        clickCategoryButton: function (category, type) {
            if (type == "parent") {
                this.subCategorySearch.category_id = category.id;
                this.getSubCategoryData();
            }

            this.productSearch.category_id = category.id;
            this.getProductData();
        },

        getSubCategoryData: function () {
            this.loading = true;
            Category.get_child(this.subCategorySearch)
                .then((response) => {
                    this.subCategoryData = response.data;
                    this.loading = false;
                    this.subCategoryTotal = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getProductData: function () {
            this.loading = true;
            Product.index(this.productSearch)
                .then((response) => {
                    this.productData = response.data;
                    this.loading = false;
                    this.productTotal = response.data.meta.total;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getProductDetail: function (product) {
            this.loading = true;
            Product.show(product.id)
                .then((response) => {
                    this.productDetail = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        getCategoryData: function () {
            Category.get_parent(this.form)
                .then((response) => {
                    this.parentCategoryData = response.data.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;

                    var text = error.response.data.message;
                    if (error.response.data.errors) {
                        for (var value in error.response.data.errors) {
                            if (text) text += "<br>";
                            text += error.response.data.errors[value];
                        }
                    }

                    this.$notify({
                        title: "Error",
                        text: text,
                        type: "error",
                        duration: 10000,
                    });
                });
        },

        editCategoryDetail: function (category) {
            this.form.id = category.id;
            this.form.name = category.name;
            this.form.category_id = category.category_id;
            this.form.priority = category.priority;
            this.form.meta_title = category.meta_title;
            this.form.meta_description = category.meta_description;
            this.form.slug = category.slug;
            this.form.store_id = category.store_id;
            this.loading = false;
            this.categoryDialog = true;
        },

        submitCategoryForm: function () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                const fd = new FormData();

                for (var element in this.form) {
                    fd.append(element, this.form[element]);
                }

                for (var fileElement in this.files) {
                    fd.append(fileElement, this.files[fileElement]);
                }

                if (this.form.id) {
                    fd.append("_method", "PATCH");
                }

                Category.store(fd, this.form.id)
                    .then(() => {
                        this.loading = false;
                        this.$refs.form.reset();

                        this.$notify({
                            title: "Success",
                            text: "Category Information Updated !!",
                            type: "success",
                            duration: 10000,
                        });

                        this.getCategoryParentData();
                        this.categoryDialog = false;
                    })
                    .catch((error) => {
                        this.loading = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },

    watch: {
        "form.category_id"(val) {
            this.getCategoryData(val);
        },
    },
};
</script>
