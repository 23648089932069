<template>
    <div class="login">
        <v-container>
            <v-row class="justify-center">
                <v-col cols="12">
                    <v-card
                        :loading="loading"
                        class="mx-auto my-12"
                        max-width="500"
                        style="padding: 20px"
                    >
                        <template slot="progress">
                            <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                        </template>

                        <v-card-title>
                            <v-img
                                :src="$store.state.logo"
                                class="pa-2"
                                contain
                                max-width="200"
                            />
                        </v-card-title>

                        <v-form v-model="valid" @submit.prevent="submitLogin" ref="form">
                            <v-container>
                                <v-col cols="12">
                                    <v-text-field
                                        outlined
                                        v-model="form.email"
                                        :rules="emailRules"
                                        label="Email"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        outlined
                                        type="password"
                                        v-model="form.password"
                                        :rules="passwordRules"
                                        label="Password"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-container>

                            <v-card-actions class="justify-center">
                                <v-btn depressed class="otpBtn" @click="submitLogin">
                                    Login
                                </v-btn>
                            </v-card-actions>
                        </v-form>

                        <v-spacer></v-spacer>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Admin from "../apis/Admin";

export default {
    name: "Login",

    created() {
        if (this.$store.getters.loggedIn) window.location.href = "dashboard";
    },

    data: () => ({
        loginScreen: null,
        valid: false,
        emailRules: [
            (v) => !!v || "Email is required",
            (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [(v) => !!v || "Password is required"],
        loading: false,
        form: {
            email: "",
            password: "",
        },
    }),

    methods: {
        submitLogin: function () {
            this.loginScreen = true;
            if (this.$refs.form.validate()) {
                Admin.login(this.form)
                    .then((response) => {
                        this.loginScreen = false;
                        this.token = response.data.data.accessToken;

                        var localItem = {
                            access_token: this.token,
                            firstName: response.data.data.firstName,
                            lastName: response.data.data.lastName,
                            email: response.data.data.email,
                            profile: response.data.data.profile,
                            adminId: response.data.data.id,
                            created_at: response.data.data.created_at,
                        };

                        localStorage.setItem("adminLogin", JSON.stringify(localItem));

                        this.$store.state.token = this.token;
                        this.$store.state.firstName = response.data.data.firstName;
                        this.$store.state.lastName = response.data.data.lastName;
                        this.$store.state.email = response.data.data.email;
                        this.$store.state.profile = response.data.data.profile;
                        this.$store.state.adminId = response.data.data.id;
                        this.$store.state.created_at = response.data.data.created_at;
                        this.$store.state.adminLogin = JSON.stringify(localItem);

                        this.$store.state.buttonPressed = "Login";

                        /* var nextUrl = this.$store.state.nextUrl;
                                    this.$store.state.nextUrl = null;

                                    if (nextUrl)
                                        this.$router.push({name:'nextUrl'});
                                    else
                                        this.$router.push({name:'dashboard'}); */

                        window.location.href = "/dashboard";
                    })
                    .catch((error) => {
                        this.loginScreen = false;

                        var text = error.response.data.message;
                        if (error.response.data.errors) {
                            for (var value in error.response.data.errors) {
                                if (text) text += "<br>";
                                text += error.response.data.errors[value];
                            }
                        }

                        this.$notify({
                            title: "Error",
                            text: text,
                            type: "error",
                            duration: 10000,
                        });
                    });
            }
        },
    },
};
</script>
