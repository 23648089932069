import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/upload-file", {
            params: form
        });
    },

    async store(form) {
        return Api.post("/upload-file", form);
    },

    async show(id) {
        return Api.post("/upload-file/" + id);
    },

    async update(form, id) {
        return Api.patch("/upload-file/" + id, form);
    },

    async delete(id) {
        return Api.delete("/upload-file/" + id);
    },
};
