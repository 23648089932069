import Api from "./Api";

export default {
    async index(form) {
        return Api.get("/store-user", form);
    },

    async store(form) {
        return Api.post("/store-user", form);
    },

    async show(form, id) {
        return Api.post("/store-user/" + id, form);
    },

    async update(form, id) {
        form.append("_method", "PATCH");
        return Api.post("/store-user/" + id, form);
    },

    async delete(id) {
        return Api.delete("/store-user/" + id);
    },
};
