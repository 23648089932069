var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"about pa-10"},[_c('v-col',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.getProductData.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Search","prepend-icon":"mdi-card-search"},model:{value:(_vm.form.search),callback:function ($$v) {_vm.$set(_vm.form, "search", $$v)},expression:"form.search"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{attrs:{"type":"submit","small":"","color":"primary","dark":""}},[_vm._v(" Search ")])],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"col-md-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.productData.data,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h4',[_vm._v("All products")])]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{attrs:{"to":"add-product","color":"primary"}},[_vm._v(" Add New Product")])],1)]},proxy:true},{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"contain":"","width":"80","height":"80","src":item.thumbnail_file.url}}),_vm._v(" "+_vm._s(item.product_name)+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v("Unit:")]),_vm._v(" "+_vm._s(item.product_unit)+" ")]}},{key:"item.product_category",fn:function(ref){
var item = ref.item;
return [(item.product_category)?_c('div',_vm._l((item.product_category),function(value,key){return _c('v-chip',{key:key,staticClass:"ma-2",attrs:{"color":"green","text-color":"white","small":""}},[_vm._v(" "+_vm._s(value.category.name)+" ")])}),1):_vm._e()]}},{key:"item.brand",fn:function(ref){
var item = ref.item;
return [(item.brand)?_c('div',[_c('v-img',{attrs:{"width":"50","height":"50","src":item.brand.logo_file.url}})],1):_vm._e()]}},{key:"item.product_status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"false-value":"Draft","true-value":"Published"},on:{"change":function($event){return _vm.productStatusChange(item)}},model:{value:(item.product_status),callback:function ($$v) {_vm.$set(item, "product_status", $$v)},expression:"item.product_status"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Actions ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push('product-view/' + item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye")]),_vm._v("  View ")],1)],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.$router.push('add-product/' + item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil")]),_vm._v("  Edit ")],1)],1),_c('v-list-item',{staticStyle:{"cursor":"pointer"}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteProduct(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete")]),_vm._v("  Delete ")],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }